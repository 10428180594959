/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
export enum DocumentCountry {
  DOCUMENT_COUNTRY_UNSPECIFIED = 0,
  AF = 1,
  AL = 2,
  DZ = 3,
  AD = 4,
  AO = 5,
  AG = 6,
  AR = 7,
  AM = 8,
  AU = 9,
  AT = 10,
  AZ = 11,
  BS = 12,
  BH = 13,
  BD = 14,
  BB = 15,
  BY = 16,
  BE = 17,
  BZ = 18,
  BJ = 19,
  BT = 20,
  BO = 21,
  BA = 22,
  BW = 23,
  BR = 24,
  BN = 25,
  BG = 26,
  BF = 27,
  BI = 28,
  CV = 29,
  KH = 30,
  CM = 31,
  CA = 32,
  CF = 33,
  TD = 34,
  CL = 35,
  CN = 36,
  CO = 37,
  KM = 38,
  CG = 39,
  CD = 40,
  CR = 41,
  CI = 42,
  HR = 43,
  CU = 44,
  CY = 45,
  CZ = 46,
  DK = 47,
  DJ = 48,
  DM = 49,
  DO = 50,
  EC = 51,
  EG = 52,
  SV = 53,
  GQ = 54,
  ER = 55,
  EE = 56,
  SZ = 57,
  ET = 58,
  FJ = 59,
  FI = 60,
  FR = 61,
  GA = 62,
  GM = 63,
  GE = 64,
  DE = 65,
  GH = 66,
  GR = 67,
  GD = 68,
  GT = 69,
  GN = 70,
  GW = 71,
  GY = 72,
  HT = 73,
  HN = 74,
  HU = 75,
  IS = 76,
  IN = 77,
  ID = 78,
  IR = 79,
  IQ = 80,
  IE = 81,
  IL = 82,
  IT = 83,
  JM = 84,
  JP = 85,
  JO = 86,
  KZ = 87,
  KE = 88,
  KI = 89,
  XK = 90,
  KW = 91,
  KG = 92,
  LA = 93,
  LV = 94,
  LB = 95,
  LS = 96,
  LR = 97,
  LY = 98,
  LI = 99,
  LT = 100,
  LU = 101,
  MG = 102,
  MW = 103,
  MY = 104,
  MV = 105,
  ML = 106,
  MT = 107,
  MH = 108,
  MR = 109,
  MU = 110,
  MX = 111,
  FM = 112,
  MD = 113,
  MC = 114,
  MN = 115,
  ME = 116,
  MA = 117,
  MZ = 118,
  MM = 119,
  NA = 120,
  NR = 121,
  NP = 122,
  NL = 123,
  NZ = 124,
  NI = 125,
  NE = 126,
  NG = 127,
  KP = 128,
  MK = 129,
  NO = 130,
  OM = 131,
  PK = 132,
  PW = 133,
  PS = 134,
  PA = 135,
  PG = 136,
  PY = 137,
  PE = 138,
  PH = 139,
  PL = 140,
  PT = 141,
  QA = 142,
  RO = 143,
  RU = 144,
  RW = 145,
  KN = 146,
  LC = 147,
  VC = 148,
  WS = 149,
  SM = 150,
  ST = 151,
  SA = 152,
  SN = 153,
  RS = 154,
  SC = 155,
  SL = 156,
  SG = 157,
  SK = 158,
  SI = 159,
  SB = 160,
  SO = 161,
  ZA = 162,
  KR = 163,
  SS = 164,
  ES = 165,
  LK = 166,
  SD = 167,
  SR = 168,
  SE = 169,
  CH = 170,
  SY = 171,
  TW = 172,
  TJ = 173,
  TZ = 174,
  TH = 175,
  TL = 176,
  TG = 177,
  TO = 178,
  TT = 179,
  TN = 180,
  TR = 181,
  TM = 182,
  TV = 183,
  UG = 184,
  UA = 185,
  AE = 186,
  GB = 187,
  US = 188,
  UY = 189,
  UZ = 190,
  VU = 191,
  VA = 192,
  VE = 193,
  VN = 194,
  YE = 195,
  ZM = 196,
  ZW = 197
}
