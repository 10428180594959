/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import {
  GrpcMessage,
  RecursivePartial,
  ToProtobufJSONOptions
} from '@ngx-grpc/common';
import { BinaryReader, BinaryWriter, ByteSource } from 'google-protobuf';
import * as googleProtobuf000 from '@ngx-grpc/well-known-types';
import * as googleProtobuf001 from '@ngx-grpc/well-known-types';
import * as goal_transaction_enums002 from './goal-transaction-enums.pb';
import * as goal_transaction_type_enums003 from './goal-transaction-type-enums.pb';
import * as goal_transaction_payment_enum004 from './goal-transaction-payment-enum.pb';
import * as goal_transaction_origin_type_enum005 from './goal-transaction-origin-type-enum.pb';
import * as googleProtobuf006 from '@ngx-grpc/well-known-types';
import * as file_message007 from './file-message.pb';
import * as common_message008 from './common-message.pb';
import * as document_country_enums009 from './document-country-enums.pb';
export enum NotificationTransactionPendingTypeEnum {
  TRANSFER = 0,
  BUY = 1,
  SALE = 2,
  WAITING_SELECT_GOAL = 3,
  OTHER = 4,
  CHANGE_PORTFOLIO = 5
}
export enum DocumentType {
  DOCUMENT = 0,
  CERTIFICATE = 1,
  VECTOR_CARTOLA = 2
}
/**
 * Message implementation for portal_user.GetMeActivityDetailRequest
 */
export class GetMeActivityDetailRequest implements GrpcMessage {
  static id = 'portal_user.GetMeActivityDetailRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetMeActivityDetailRequest();
    GetMeActivityDetailRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetMeActivityDetailRequest) {
    _instance.transactionRequestId = _instance.transactionRequestId || 0;
    _instance.fromGoalId = _instance.fromGoalId || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetMeActivityDetailRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.transactionRequestId = _reader.readInt32();
          break;
        case 2:
          _instance.fromGoalId = new googleProtobuf000.Int32Value();
          _reader.readMessage(
            _instance.fromGoalId,
            googleProtobuf000.Int32Value.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    GetMeActivityDetailRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetMeActivityDetailRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.transactionRequestId) {
      _writer.writeInt32(1, _instance.transactionRequestId);
    }
    if (_instance.fromGoalId) {
      _writer.writeMessage(
        2,
        _instance.fromGoalId as any,
        googleProtobuf000.Int32Value.serializeBinaryToWriter
      );
    }
  }

  private _transactionRequestId: number;
  private _fromGoalId?: googleProtobuf000.Int32Value;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetMeActivityDetailRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetMeActivityDetailRequest.AsObject>) {
    _value = _value || {};
    this.transactionRequestId = _value.transactionRequestId;
    this.fromGoalId = _value.fromGoalId
      ? new googleProtobuf000.Int32Value(_value.fromGoalId)
      : undefined;
    GetMeActivityDetailRequest.refineValues(this);
  }
  get transactionRequestId(): number {
    return this._transactionRequestId;
  }
  set transactionRequestId(value: number) {
    this._transactionRequestId = value;
  }
  get fromGoalId(): googleProtobuf000.Int32Value | undefined {
    return this._fromGoalId;
  }
  set fromGoalId(value: googleProtobuf000.Int32Value | undefined) {
    this._fromGoalId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetMeActivityDetailRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetMeActivityDetailRequest.AsObject {
    return {
      transactionRequestId: this.transactionRequestId,
      fromGoalId: this.fromGoalId ? this.fromGoalId.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetMeActivityDetailRequest.AsProtobufJSON {
    return {
      transactionRequestId: this.transactionRequestId,
      fromGoalId: this.fromGoalId
        ? this.fromGoalId.toProtobufJSON(options)
        : null
    };
  }
}
export module GetMeActivityDetailRequest {
  /**
   * Standard JavaScript object representation for GetMeActivityDetailRequest
   */
  export interface AsObject {
    transactionRequestId: number;
    fromGoalId?: googleProtobuf000.Int32Value.AsObject;
  }

  /**
   * Protobuf JSON representation for GetMeActivityDetailRequest
   */
  export interface AsProtobufJSON {
    transactionRequestId: number;
    fromGoalId: googleProtobuf000.Int32Value.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for portal_user.GetMeActivityDetailResponse
 */
export class GetMeActivityDetailResponse implements GrpcMessage {
  static id = 'portal_user.GetMeActivityDetailResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetMeActivityDetailResponse();
    GetMeActivityDetailResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetMeActivityDetailResponse) {
    _instance.type = _instance.type || 0;
    _instance.amount = _instance.amount || '';
    _instance.phases = _instance.phases || [];
    _instance.details = _instance.details || [];
    _instance.comment = _instance.comment || undefined;
    _instance.isPartial = _instance.isPartial || false;
    _instance.description = _instance.description || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetMeActivityDetailResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.type = _reader.readEnum();
          break;
        case 2:
          _instance.amount = _reader.readString();
          break;
        case 3:
          const messageInitializer3 = new ActivityPhaseModel();
          _reader.readMessage(
            messageInitializer3,
            ActivityPhaseModel.deserializeBinaryFromReader
          );
          (_instance.phases = _instance.phases || []).push(messageInitializer3);
          break;
        case 4:
          const messageInitializer4 = new ActivityDetailModel();
          _reader.readMessage(
            messageInitializer4,
            ActivityDetailModel.deserializeBinaryFromReader
          );
          (_instance.details = _instance.details || []).push(
            messageInitializer4
          );
          break;
        case 5:
          _instance.comment = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.comment,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 6:
          _instance.isPartial = _reader.readBool();
          break;
        case 7:
          _instance.description = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.description,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    GetMeActivityDetailResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetMeActivityDetailResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.type) {
      _writer.writeEnum(1, _instance.type);
    }
    if (_instance.amount) {
      _writer.writeString(2, _instance.amount);
    }
    if (_instance.phases && _instance.phases.length) {
      _writer.writeRepeatedMessage(
        3,
        _instance.phases as any,
        ActivityPhaseModel.serializeBinaryToWriter
      );
    }
    if (_instance.details && _instance.details.length) {
      _writer.writeRepeatedMessage(
        4,
        _instance.details as any,
        ActivityDetailModel.serializeBinaryToWriter
      );
    }
    if (_instance.comment) {
      _writer.writeMessage(
        5,
        _instance.comment as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.isPartial) {
      _writer.writeBool(6, _instance.isPartial);
    }
    if (_instance.description) {
      _writer.writeMessage(
        7,
        _instance.description as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
  }

  private _type: goal_transaction_enums002.TransactionRequestType;
  private _amount: string;
  private _phases?: ActivityPhaseModel[];
  private _details?: ActivityDetailModel[];
  private _comment?: googleProtobuf000.StringValue;
  private _isPartial: boolean;
  private _description?: googleProtobuf000.StringValue;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetMeActivityDetailResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetMeActivityDetailResponse.AsObject>) {
    _value = _value || {};
    this.type = _value.type;
    this.amount = _value.amount;
    this.phases = (_value.phases || []).map(m => new ActivityPhaseModel(m));
    this.details = (_value.details || []).map(m => new ActivityDetailModel(m));
    this.comment = _value.comment
      ? new googleProtobuf000.StringValue(_value.comment)
      : undefined;
    this.isPartial = _value.isPartial;
    this.description = _value.description
      ? new googleProtobuf000.StringValue(_value.description)
      : undefined;
    GetMeActivityDetailResponse.refineValues(this);
  }
  get type(): goal_transaction_enums002.TransactionRequestType {
    return this._type;
  }
  set type(value: goal_transaction_enums002.TransactionRequestType) {
    this._type = value;
  }
  get amount(): string {
    return this._amount;
  }
  set amount(value: string) {
    this._amount = value;
  }
  get phases(): ActivityPhaseModel[] | undefined {
    return this._phases;
  }
  set phases(value: ActivityPhaseModel[] | undefined) {
    this._phases = value;
  }
  get details(): ActivityDetailModel[] | undefined {
    return this._details;
  }
  set details(value: ActivityDetailModel[] | undefined) {
    this._details = value;
  }
  get comment(): googleProtobuf000.StringValue | undefined {
    return this._comment;
  }
  set comment(value: googleProtobuf000.StringValue | undefined) {
    this._comment = value;
  }
  get isPartial(): boolean {
    return this._isPartial;
  }
  set isPartial(value: boolean) {
    this._isPartial = value;
  }
  get description(): googleProtobuf000.StringValue | undefined {
    return this._description;
  }
  set description(value: googleProtobuf000.StringValue | undefined) {
    this._description = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetMeActivityDetailResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetMeActivityDetailResponse.AsObject {
    return {
      type: this.type,
      amount: this.amount,
      phases: (this.phases || []).map(m => m.toObject()),
      details: (this.details || []).map(m => m.toObject()),
      comment: this.comment ? this.comment.toObject() : undefined,
      isPartial: this.isPartial,
      description: this.description ? this.description.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetMeActivityDetailResponse.AsProtobufJSON {
    return {
      type:
        goal_transaction_enums002.TransactionRequestType[
          this.type === null || this.type === undefined ? 0 : this.type
        ],
      amount: this.amount,
      phases: (this.phases || []).map(m => m.toProtobufJSON(options)),
      details: (this.details || []).map(m => m.toProtobufJSON(options)),
      comment: this.comment ? this.comment.toProtobufJSON(options) : null,
      isPartial: this.isPartial,
      description: this.description
        ? this.description.toProtobufJSON(options)
        : null
    };
  }
}
export module GetMeActivityDetailResponse {
  /**
   * Standard JavaScript object representation for GetMeActivityDetailResponse
   */
  export interface AsObject {
    type: goal_transaction_enums002.TransactionRequestType;
    amount: string;
    phases?: ActivityPhaseModel.AsObject[];
    details?: ActivityDetailModel.AsObject[];
    comment?: googleProtobuf000.StringValue.AsObject;
    isPartial: boolean;
    description?: googleProtobuf000.StringValue.AsObject;
  }

  /**
   * Protobuf JSON representation for GetMeActivityDetailResponse
   */
  export interface AsProtobufJSON {
    type: string;
    amount: string;
    phases: ActivityPhaseModel.AsProtobufJSON[] | null;
    details: ActivityDetailModel.AsProtobufJSON[] | null;
    comment: googleProtobuf000.StringValue.AsProtobufJSON | null;
    isPartial: boolean;
    description: googleProtobuf000.StringValue.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for portal_user.ActivityPhaseModel
 */
export class ActivityPhaseModel implements GrpcMessage {
  static id = 'portal_user.ActivityPhaseModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ActivityPhaseModel();
    ActivityPhaseModel.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ActivityPhaseModel) {
    _instance.title = _instance.title || '';
    _instance.order = _instance.order || 0;
    _instance.completed = _instance.completed || false;
    _instance.date = _instance.date || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ActivityPhaseModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.title = _reader.readString();
          break;
        case 2:
          _instance.order = _reader.readInt32();
          break;
        case 3:
          _instance.completed = _reader.readBool();
          break;
        case 4:
          _instance.date = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.date,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    ActivityPhaseModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ActivityPhaseModel,
    _writer: BinaryWriter
  ) {
    if (_instance.title) {
      _writer.writeString(1, _instance.title);
    }
    if (_instance.order) {
      _writer.writeInt32(2, _instance.order);
    }
    if (_instance.completed) {
      _writer.writeBool(3, _instance.completed);
    }
    if (_instance.date) {
      _writer.writeMessage(
        4,
        _instance.date as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
  }

  private _title: string;
  private _order: number;
  private _completed: boolean;
  private _date?: googleProtobuf001.Timestamp;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ActivityPhaseModel to deeply clone from
   */
  constructor(_value?: RecursivePartial<ActivityPhaseModel.AsObject>) {
    _value = _value || {};
    this.title = _value.title;
    this.order = _value.order;
    this.completed = _value.completed;
    this.date = _value.date
      ? new googleProtobuf001.Timestamp(_value.date)
      : undefined;
    ActivityPhaseModel.refineValues(this);
  }
  get title(): string {
    return this._title;
  }
  set title(value: string) {
    this._title = value;
  }
  get order(): number {
    return this._order;
  }
  set order(value: number) {
    this._order = value;
  }
  get completed(): boolean {
    return this._completed;
  }
  set completed(value: boolean) {
    this._completed = value;
  }
  get date(): googleProtobuf001.Timestamp | undefined {
    return this._date;
  }
  set date(value: googleProtobuf001.Timestamp | undefined) {
    this._date = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ActivityPhaseModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ActivityPhaseModel.AsObject {
    return {
      title: this.title,
      order: this.order,
      completed: this.completed,
      date: this.date ? this.date.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ActivityPhaseModel.AsProtobufJSON {
    return {
      title: this.title,
      order: this.order,
      completed: this.completed,
      date: this.date ? this.date.toProtobufJSON(options) : null
    };
  }
}
export module ActivityPhaseModel {
  /**
   * Standard JavaScript object representation for ActivityPhaseModel
   */
  export interface AsObject {
    title: string;
    order: number;
    completed: boolean;
    date?: googleProtobuf001.Timestamp.AsObject;
  }

  /**
   * Protobuf JSON representation for ActivityPhaseModel
   */
  export interface AsProtobufJSON {
    title: string;
    order: number;
    completed: boolean;
    date: googleProtobuf001.Timestamp.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for portal_user.ActivityDetailModel
 */
export class ActivityDetailModel implements GrpcMessage {
  static id = 'portal_user.ActivityDetailModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ActivityDetailModel();
    ActivityDetailModel.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ActivityDetailModel) {
    _instance.amountTotal = _instance.amountTotal || '';
    _instance.type = _instance.type || '';
    _instance.funds = _instance.funds || [];
    _instance.description = _instance.description || '';
    _instance.cash = _instance.cash || undefined;
    _instance.cashReinvestment = _instance.cashReinvestment || undefined;
    _instance.brokerCommission = _instance.brokerCommission || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ActivityDetailModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.amountTotal = _reader.readString();
          break;
        case 2:
          _instance.type = _reader.readString();
          break;
        case 3:
          const messageInitializer3 = new ActivityDetailFundingModel();
          _reader.readMessage(
            messageInitializer3,
            ActivityDetailFundingModel.deserializeBinaryFromReader
          );
          (_instance.funds = _instance.funds || []).push(messageInitializer3);
          break;
        case 4:
          _instance.description = _reader.readString();
          break;
        case 5:
          _instance.cash = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.cash,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 6:
          _instance.cashReinvestment = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.cashReinvestment,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 7:
          _instance.brokerCommission = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.brokerCommission,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    ActivityDetailModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ActivityDetailModel,
    _writer: BinaryWriter
  ) {
    if (_instance.amountTotal) {
      _writer.writeString(1, _instance.amountTotal);
    }
    if (_instance.type) {
      _writer.writeString(2, _instance.type);
    }
    if (_instance.funds && _instance.funds.length) {
      _writer.writeRepeatedMessage(
        3,
        _instance.funds as any,
        ActivityDetailFundingModel.serializeBinaryToWriter
      );
    }
    if (_instance.description) {
      _writer.writeString(4, _instance.description);
    }
    if (_instance.cash) {
      _writer.writeMessage(
        5,
        _instance.cash as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.cashReinvestment) {
      _writer.writeMessage(
        6,
        _instance.cashReinvestment as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.brokerCommission) {
      _writer.writeMessage(
        7,
        _instance.brokerCommission as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
  }

  private _amountTotal: string;
  private _type: string;
  private _funds?: ActivityDetailFundingModel[];
  private _description: string;
  private _cash?: googleProtobuf000.StringValue;
  private _cashReinvestment?: googleProtobuf000.StringValue;
  private _brokerCommission?: googleProtobuf000.StringValue;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ActivityDetailModel to deeply clone from
   */
  constructor(_value?: RecursivePartial<ActivityDetailModel.AsObject>) {
    _value = _value || {};
    this.amountTotal = _value.amountTotal;
    this.type = _value.type;
    this.funds = (_value.funds || []).map(
      m => new ActivityDetailFundingModel(m)
    );
    this.description = _value.description;
    this.cash = _value.cash
      ? new googleProtobuf000.StringValue(_value.cash)
      : undefined;
    this.cashReinvestment = _value.cashReinvestment
      ? new googleProtobuf000.StringValue(_value.cashReinvestment)
      : undefined;
    this.brokerCommission = _value.brokerCommission
      ? new googleProtobuf000.StringValue(_value.brokerCommission)
      : undefined;
    ActivityDetailModel.refineValues(this);
  }
  get amountTotal(): string {
    return this._amountTotal;
  }
  set amountTotal(value: string) {
    this._amountTotal = value;
  }
  get type(): string {
    return this._type;
  }
  set type(value: string) {
    this._type = value;
  }
  get funds(): ActivityDetailFundingModel[] | undefined {
    return this._funds;
  }
  set funds(value: ActivityDetailFundingModel[] | undefined) {
    this._funds = value;
  }
  get description(): string {
    return this._description;
  }
  set description(value: string) {
    this._description = value;
  }
  get cash(): googleProtobuf000.StringValue | undefined {
    return this._cash;
  }
  set cash(value: googleProtobuf000.StringValue | undefined) {
    this._cash = value;
  }
  get cashReinvestment(): googleProtobuf000.StringValue | undefined {
    return this._cashReinvestment;
  }
  set cashReinvestment(value: googleProtobuf000.StringValue | undefined) {
    this._cashReinvestment = value;
  }
  get brokerCommission(): googleProtobuf000.StringValue | undefined {
    return this._brokerCommission;
  }
  set brokerCommission(value: googleProtobuf000.StringValue | undefined) {
    this._brokerCommission = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ActivityDetailModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ActivityDetailModel.AsObject {
    return {
      amountTotal: this.amountTotal,
      type: this.type,
      funds: (this.funds || []).map(m => m.toObject()),
      description: this.description,
      cash: this.cash ? this.cash.toObject() : undefined,
      cashReinvestment: this.cashReinvestment
        ? this.cashReinvestment.toObject()
        : undefined,
      brokerCommission: this.brokerCommission
        ? this.brokerCommission.toObject()
        : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ActivityDetailModel.AsProtobufJSON {
    return {
      amountTotal: this.amountTotal,
      type: this.type,
      funds: (this.funds || []).map(m => m.toProtobufJSON(options)),
      description: this.description,
      cash: this.cash ? this.cash.toProtobufJSON(options) : null,
      cashReinvestment: this.cashReinvestment
        ? this.cashReinvestment.toProtobufJSON(options)
        : null,
      brokerCommission: this.brokerCommission
        ? this.brokerCommission.toProtobufJSON(options)
        : null
    };
  }
}
export module ActivityDetailModel {
  /**
   * Standard JavaScript object representation for ActivityDetailModel
   */
  export interface AsObject {
    amountTotal: string;
    type: string;
    funds?: ActivityDetailFundingModel.AsObject[];
    description: string;
    cash?: googleProtobuf000.StringValue.AsObject;
    cashReinvestment?: googleProtobuf000.StringValue.AsObject;
    brokerCommission?: googleProtobuf000.StringValue.AsObject;
  }

  /**
   * Protobuf JSON representation for ActivityDetailModel
   */
  export interface AsProtobufJSON {
    amountTotal: string;
    type: string;
    funds: ActivityDetailFundingModel.AsProtobufJSON[] | null;
    description: string;
    cash: googleProtobuf000.StringValue.AsProtobufJSON | null;
    cashReinvestment: googleProtobuf000.StringValue.AsProtobufJSON | null;
    brokerCommission: googleProtobuf000.StringValue.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for portal_user.ActivityDetailFundingModel
 */
export class ActivityDetailFundingModel implements GrpcMessage {
  static id = 'portal_user.ActivityDetailFundingModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ActivityDetailFundingModel();
    ActivityDetailFundingModel.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ActivityDetailFundingModel) {
    _instance.amount = _instance.amount || '';
    _instance.price = _instance.price || '';
    _instance.mnemonic = _instance.mnemonic || '';
    _instance.quotas = _instance.quotas || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ActivityDetailFundingModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.amount = _reader.readString();
          break;
        case 2:
          _instance.price = _reader.readString();
          break;
        case 3:
          _instance.mnemonic = _reader.readString();
          break;
        case 4:
          _instance.quotas = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    ActivityDetailFundingModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ActivityDetailFundingModel,
    _writer: BinaryWriter
  ) {
    if (_instance.amount) {
      _writer.writeString(1, _instance.amount);
    }
    if (_instance.price) {
      _writer.writeString(2, _instance.price);
    }
    if (_instance.mnemonic) {
      _writer.writeString(3, _instance.mnemonic);
    }
    if (_instance.quotas) {
      _writer.writeString(4, _instance.quotas);
    }
  }

  private _amount: string;
  private _price: string;
  private _mnemonic: string;
  private _quotas: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ActivityDetailFundingModel to deeply clone from
   */
  constructor(_value?: RecursivePartial<ActivityDetailFundingModel.AsObject>) {
    _value = _value || {};
    this.amount = _value.amount;
    this.price = _value.price;
    this.mnemonic = _value.mnemonic;
    this.quotas = _value.quotas;
    ActivityDetailFundingModel.refineValues(this);
  }
  get amount(): string {
    return this._amount;
  }
  set amount(value: string) {
    this._amount = value;
  }
  get price(): string {
    return this._price;
  }
  set price(value: string) {
    this._price = value;
  }
  get mnemonic(): string {
    return this._mnemonic;
  }
  set mnemonic(value: string) {
    this._mnemonic = value;
  }
  get quotas(): string {
    return this._quotas;
  }
  set quotas(value: string) {
    this._quotas = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ActivityDetailFundingModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ActivityDetailFundingModel.AsObject {
    return {
      amount: this.amount,
      price: this.price,
      mnemonic: this.mnemonic,
      quotas: this.quotas
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ActivityDetailFundingModel.AsProtobufJSON {
    return {
      amount: this.amount,
      price: this.price,
      mnemonic: this.mnemonic,
      quotas: this.quotas
    };
  }
}
export module ActivityDetailFundingModel {
  /**
   * Standard JavaScript object representation for ActivityDetailFundingModel
   */
  export interface AsObject {
    amount: string;
    price: string;
    mnemonic: string;
    quotas: string;
  }

  /**
   * Protobuf JSON representation for ActivityDetailFundingModel
   */
  export interface AsProtobufJSON {
    amount: string;
    price: string;
    mnemonic: string;
    quotas: string;
  }
}

/**
 * Message implementation for portal_user.GetMeActivitiesRequest
 */
export class GetMeActivitiesRequest implements GrpcMessage {
  static id = 'portal_user.GetMeActivitiesRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetMeActivitiesRequest();
    GetMeActivitiesRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetMeActivitiesRequest) {
    _instance.goalIds = _instance.goalIds || [];
    _instance.from = _instance.from || undefined;
    _instance.to = _instance.to || undefined;
    _instance.types = _instance.types || [];
    _instance.currencyId = _instance.currencyId || undefined;
    _instance.page = _instance.page || undefined;
    _instance.size = _instance.size || undefined;
    _instance.isGeneralActivity = _instance.isGeneralActivity || false;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetMeActivitiesRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new googleProtobuf000.Int32Value();
          _reader.readMessage(
            messageInitializer1,
            googleProtobuf000.Int32Value.deserializeBinaryFromReader
          );
          (_instance.goalIds = _instance.goalIds || []).push(
            messageInitializer1
          );
          break;
        case 2:
          _instance.from = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.from,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.to = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.to,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 4:
          (_instance.types = _instance.types || []).push(
            ...(_reader.readPackedEnum() || [])
          );
          break;
        case 5:
          _instance.currencyId = new googleProtobuf000.Int32Value();
          _reader.readMessage(
            _instance.currencyId,
            googleProtobuf000.Int32Value.deserializeBinaryFromReader
          );
          break;
        case 6:
          _instance.page = new googleProtobuf000.Int32Value();
          _reader.readMessage(
            _instance.page,
            googleProtobuf000.Int32Value.deserializeBinaryFromReader
          );
          break;
        case 7:
          _instance.size = new googleProtobuf000.Int32Value();
          _reader.readMessage(
            _instance.size,
            googleProtobuf000.Int32Value.deserializeBinaryFromReader
          );
          break;
        case 8:
          _instance.isGeneralActivity = _reader.readBool();
          break;
        default:
          _reader.skipField();
      }
    }

    GetMeActivitiesRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetMeActivitiesRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.goalIds && _instance.goalIds.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.goalIds as any,
        googleProtobuf000.Int32Value.serializeBinaryToWriter
      );
    }
    if (_instance.from) {
      _writer.writeMessage(
        2,
        _instance.from as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.to) {
      _writer.writeMessage(
        3,
        _instance.to as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.types && _instance.types.length) {
      _writer.writePackedEnum(4, _instance.types);
    }
    if (_instance.currencyId) {
      _writer.writeMessage(
        5,
        _instance.currencyId as any,
        googleProtobuf000.Int32Value.serializeBinaryToWriter
      );
    }
    if (_instance.page) {
      _writer.writeMessage(
        6,
        _instance.page as any,
        googleProtobuf000.Int32Value.serializeBinaryToWriter
      );
    }
    if (_instance.size) {
      _writer.writeMessage(
        7,
        _instance.size as any,
        googleProtobuf000.Int32Value.serializeBinaryToWriter
      );
    }
    if (_instance.isGeneralActivity) {
      _writer.writeBool(8, _instance.isGeneralActivity);
    }
  }

  private _goalIds?: googleProtobuf000.Int32Value[];
  private _from?: googleProtobuf001.Timestamp;
  private _to?: googleProtobuf001.Timestamp;
  private _types: goal_transaction_enums002.TransactionRequestType[];
  private _currencyId?: googleProtobuf000.Int32Value;
  private _page?: googleProtobuf000.Int32Value;
  private _size?: googleProtobuf000.Int32Value;
  private _isGeneralActivity: boolean;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetMeActivitiesRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetMeActivitiesRequest.AsObject>) {
    _value = _value || {};
    this.goalIds = (_value.goalIds || []).map(
      m => new googleProtobuf000.Int32Value(m)
    );
    this.from = _value.from
      ? new googleProtobuf001.Timestamp(_value.from)
      : undefined;
    this.to = _value.to
      ? new googleProtobuf001.Timestamp(_value.to)
      : undefined;
    this.types = (_value.types || []).slice();
    this.currencyId = _value.currencyId
      ? new googleProtobuf000.Int32Value(_value.currencyId)
      : undefined;
    this.page = _value.page
      ? new googleProtobuf000.Int32Value(_value.page)
      : undefined;
    this.size = _value.size
      ? new googleProtobuf000.Int32Value(_value.size)
      : undefined;
    this.isGeneralActivity = _value.isGeneralActivity;
    GetMeActivitiesRequest.refineValues(this);
  }
  get goalIds(): googleProtobuf000.Int32Value[] | undefined {
    return this._goalIds;
  }
  set goalIds(value: googleProtobuf000.Int32Value[] | undefined) {
    this._goalIds = value;
  }
  get from(): googleProtobuf001.Timestamp | undefined {
    return this._from;
  }
  set from(value: googleProtobuf001.Timestamp | undefined) {
    this._from = value;
  }
  get to(): googleProtobuf001.Timestamp | undefined {
    return this._to;
  }
  set to(value: googleProtobuf001.Timestamp | undefined) {
    this._to = value;
  }
  get types(): goal_transaction_enums002.TransactionRequestType[] {
    return this._types;
  }
  set types(value: goal_transaction_enums002.TransactionRequestType[]) {
    this._types = value;
  }
  get currencyId(): googleProtobuf000.Int32Value | undefined {
    return this._currencyId;
  }
  set currencyId(value: googleProtobuf000.Int32Value | undefined) {
    this._currencyId = value;
  }
  get page(): googleProtobuf000.Int32Value | undefined {
    return this._page;
  }
  set page(value: googleProtobuf000.Int32Value | undefined) {
    this._page = value;
  }
  get size(): googleProtobuf000.Int32Value | undefined {
    return this._size;
  }
  set size(value: googleProtobuf000.Int32Value | undefined) {
    this._size = value;
  }
  get isGeneralActivity(): boolean {
    return this._isGeneralActivity;
  }
  set isGeneralActivity(value: boolean) {
    this._isGeneralActivity = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetMeActivitiesRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetMeActivitiesRequest.AsObject {
    return {
      goalIds: (this.goalIds || []).map(m => m.toObject()),
      from: this.from ? this.from.toObject() : undefined,
      to: this.to ? this.to.toObject() : undefined,
      types: (this.types || []).slice(),
      currencyId: this.currencyId ? this.currencyId.toObject() : undefined,
      page: this.page ? this.page.toObject() : undefined,
      size: this.size ? this.size.toObject() : undefined,
      isGeneralActivity: this.isGeneralActivity
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetMeActivitiesRequest.AsProtobufJSON {
    return {
      goalIds: (this.goalIds || []).map(m => m.toProtobufJSON(options)),
      from: this.from ? this.from.toProtobufJSON(options) : null,
      to: this.to ? this.to.toProtobufJSON(options) : null,
      types: (this.types || []).map(
        v => goal_transaction_enums002.TransactionRequestType[v]
      ),
      currencyId: this.currencyId
        ? this.currencyId.toProtobufJSON(options)
        : null,
      page: this.page ? this.page.toProtobufJSON(options) : null,
      size: this.size ? this.size.toProtobufJSON(options) : null,
      isGeneralActivity: this.isGeneralActivity
    };
  }
}
export module GetMeActivitiesRequest {
  /**
   * Standard JavaScript object representation for GetMeActivitiesRequest
   */
  export interface AsObject {
    goalIds?: googleProtobuf000.Int32Value.AsObject[];
    from?: googleProtobuf001.Timestamp.AsObject;
    to?: googleProtobuf001.Timestamp.AsObject;
    types: goal_transaction_enums002.TransactionRequestType[];
    currencyId?: googleProtobuf000.Int32Value.AsObject;
    page?: googleProtobuf000.Int32Value.AsObject;
    size?: googleProtobuf000.Int32Value.AsObject;
    isGeneralActivity: boolean;
  }

  /**
   * Protobuf JSON representation for GetMeActivitiesRequest
   */
  export interface AsProtobufJSON {
    goalIds: googleProtobuf000.Int32Value.AsProtobufJSON[] | null;
    from: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    to: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    types: string[];
    currencyId: googleProtobuf000.Int32Value.AsProtobufJSON | null;
    page: googleProtobuf000.Int32Value.AsProtobufJSON | null;
    size: googleProtobuf000.Int32Value.AsProtobufJSON | null;
    isGeneralActivity: boolean;
  }
}

/**
 * Message implementation for portal_user.GetMeActivitiesResponse
 */
export class GetMeActivitiesResponse implements GrpcMessage {
  static id = 'portal_user.GetMeActivitiesResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetMeActivitiesResponse();
    GetMeActivitiesResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetMeActivitiesResponse) {
    _instance.values = _instance.values || [];
    _instance.currentPage = _instance.currentPage || 0;
    _instance.pageCount = _instance.pageCount || 0;
    _instance.pageSize = _instance.pageSize || 0;
    _instance.rowCount = _instance.rowCount || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetMeActivitiesResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new GetMeActivity();
          _reader.readMessage(
            messageInitializer1,
            GetMeActivity.deserializeBinaryFromReader
          );
          (_instance.values = _instance.values || []).push(messageInitializer1);
          break;
        case 2:
          _instance.currentPage = _reader.readInt32();
          break;
        case 3:
          _instance.pageCount = _reader.readInt32();
          break;
        case 4:
          _instance.pageSize = _reader.readInt32();
          break;
        case 5:
          _instance.rowCount = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    GetMeActivitiesResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetMeActivitiesResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.values && _instance.values.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.values as any,
        GetMeActivity.serializeBinaryToWriter
      );
    }
    if (_instance.currentPage) {
      _writer.writeInt32(2, _instance.currentPage);
    }
    if (_instance.pageCount) {
      _writer.writeInt32(3, _instance.pageCount);
    }
    if (_instance.pageSize) {
      _writer.writeInt32(4, _instance.pageSize);
    }
    if (_instance.rowCount) {
      _writer.writeInt32(5, _instance.rowCount);
    }
  }

  private _values?: GetMeActivity[];
  private _currentPage: number;
  private _pageCount: number;
  private _pageSize: number;
  private _rowCount: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetMeActivitiesResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetMeActivitiesResponse.AsObject>) {
    _value = _value || {};
    this.values = (_value.values || []).map(m => new GetMeActivity(m));
    this.currentPage = _value.currentPage;
    this.pageCount = _value.pageCount;
    this.pageSize = _value.pageSize;
    this.rowCount = _value.rowCount;
    GetMeActivitiesResponse.refineValues(this);
  }
  get values(): GetMeActivity[] | undefined {
    return this._values;
  }
  set values(value: GetMeActivity[] | undefined) {
    this._values = value;
  }
  get currentPage(): number {
    return this._currentPage;
  }
  set currentPage(value: number) {
    this._currentPage = value;
  }
  get pageCount(): number {
    return this._pageCount;
  }
  set pageCount(value: number) {
    this._pageCount = value;
  }
  get pageSize(): number {
    return this._pageSize;
  }
  set pageSize(value: number) {
    this._pageSize = value;
  }
  get rowCount(): number {
    return this._rowCount;
  }
  set rowCount(value: number) {
    this._rowCount = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetMeActivitiesResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetMeActivitiesResponse.AsObject {
    return {
      values: (this.values || []).map(m => m.toObject()),
      currentPage: this.currentPage,
      pageCount: this.pageCount,
      pageSize: this.pageSize,
      rowCount: this.rowCount
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetMeActivitiesResponse.AsProtobufJSON {
    return {
      values: (this.values || []).map(m => m.toProtobufJSON(options)),
      currentPage: this.currentPage,
      pageCount: this.pageCount,
      pageSize: this.pageSize,
      rowCount: this.rowCount
    };
  }
}
export module GetMeActivitiesResponse {
  /**
   * Standard JavaScript object representation for GetMeActivitiesResponse
   */
  export interface AsObject {
    values?: GetMeActivity.AsObject[];
    currentPage: number;
    pageCount: number;
    pageSize: number;
    rowCount: number;
  }

  /**
   * Protobuf JSON representation for GetMeActivitiesResponse
   */
  export interface AsProtobufJSON {
    values: GetMeActivity.AsProtobufJSON[] | null;
    currentPage: number;
    pageCount: number;
    pageSize: number;
    rowCount: number;
  }
}

/**
 * Message implementation for portal_user.GetMeActivity
 */
export class GetMeActivity implements GrpcMessage {
  static id = 'portal_user.GetMeActivity';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetMeActivity();
    GetMeActivity.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetMeActivity) {
    _instance.requestId = _instance.requestId || undefined;
    _instance.goalTransactionId = _instance.goalTransactionId || undefined;
    _instance.date = _instance.date || undefined;
    _instance.goals = _instance.goals || [];
    _instance.type = _instance.type || 0;
    _instance.description = _instance.description || '';
    _instance.amount = _instance.amount || '';
    _instance.isPositive = _instance.isPositive || false;
    _instance.isProcessed = _instance.isProcessed || false;
    _instance.isPartial = _instance.isPartial || false;
    _instance.descriptionTooltip = _instance.descriptionTooltip || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetMeActivity,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.requestId = new googleProtobuf000.Int32Value();
          _reader.readMessage(
            _instance.requestId,
            googleProtobuf000.Int32Value.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.goalTransactionId = new googleProtobuf000.Int32Value();
          _reader.readMessage(
            _instance.goalTransactionId,
            googleProtobuf000.Int32Value.deserializeBinaryFromReader
          );
          break;
        case 5:
          _instance.date = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.date,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 6:
          const messageInitializer6 = new GetMeActivity.Goal();
          _reader.readMessage(
            messageInitializer6,
            GetMeActivity.Goal.deserializeBinaryFromReader
          );
          (_instance.goals = _instance.goals || []).push(messageInitializer6);
          break;
        case 7:
          _instance.type = _reader.readEnum();
          break;
        case 8:
          _instance.description = _reader.readString();
          break;
        case 9:
          _instance.amount = _reader.readString();
          break;
        case 10:
          _instance.isPositive = _reader.readBool();
          break;
        case 11:
          _instance.isProcessed = _reader.readBool();
          break;
        case 12:
          _instance.isPartial = _reader.readBool();
          break;
        case 13:
          _instance.descriptionTooltip = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    GetMeActivity.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetMeActivity,
    _writer: BinaryWriter
  ) {
    if (_instance.requestId) {
      _writer.writeMessage(
        1,
        _instance.requestId as any,
        googleProtobuf000.Int32Value.serializeBinaryToWriter
      );
    }
    if (_instance.goalTransactionId) {
      _writer.writeMessage(
        2,
        _instance.goalTransactionId as any,
        googleProtobuf000.Int32Value.serializeBinaryToWriter
      );
    }
    if (_instance.date) {
      _writer.writeMessage(
        5,
        _instance.date as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.goals && _instance.goals.length) {
      _writer.writeRepeatedMessage(
        6,
        _instance.goals as any,
        GetMeActivity.Goal.serializeBinaryToWriter
      );
    }
    if (_instance.type) {
      _writer.writeEnum(7, _instance.type);
    }
    if (_instance.description) {
      _writer.writeString(8, _instance.description);
    }
    if (_instance.amount) {
      _writer.writeString(9, _instance.amount);
    }
    if (_instance.isPositive) {
      _writer.writeBool(10, _instance.isPositive);
    }
    if (_instance.isProcessed) {
      _writer.writeBool(11, _instance.isProcessed);
    }
    if (_instance.isPartial) {
      _writer.writeBool(12, _instance.isPartial);
    }
    if (_instance.descriptionTooltip) {
      _writer.writeString(13, _instance.descriptionTooltip);
    }
  }

  private _requestId?: googleProtobuf000.Int32Value;
  private _goalTransactionId?: googleProtobuf000.Int32Value;
  private _date?: googleProtobuf001.Timestamp;
  private _goals?: GetMeActivity.Goal[];
  private _type: goal_transaction_enums002.TransactionRequestType;
  private _description: string;
  private _amount: string;
  private _isPositive: boolean;
  private _isProcessed: boolean;
  private _isPartial: boolean;
  private _descriptionTooltip: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetMeActivity to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetMeActivity.AsObject>) {
    _value = _value || {};
    this.requestId = _value.requestId
      ? new googleProtobuf000.Int32Value(_value.requestId)
      : undefined;
    this.goalTransactionId = _value.goalTransactionId
      ? new googleProtobuf000.Int32Value(_value.goalTransactionId)
      : undefined;
    this.date = _value.date
      ? new googleProtobuf001.Timestamp(_value.date)
      : undefined;
    this.goals = (_value.goals || []).map(m => new GetMeActivity.Goal(m));
    this.type = _value.type;
    this.description = _value.description;
    this.amount = _value.amount;
    this.isPositive = _value.isPositive;
    this.isProcessed = _value.isProcessed;
    this.isPartial = _value.isPartial;
    this.descriptionTooltip = _value.descriptionTooltip;
    GetMeActivity.refineValues(this);
  }
  get requestId(): googleProtobuf000.Int32Value | undefined {
    return this._requestId;
  }
  set requestId(value: googleProtobuf000.Int32Value | undefined) {
    this._requestId = value;
  }
  get goalTransactionId(): googleProtobuf000.Int32Value | undefined {
    return this._goalTransactionId;
  }
  set goalTransactionId(value: googleProtobuf000.Int32Value | undefined) {
    this._goalTransactionId = value;
  }
  get date(): googleProtobuf001.Timestamp | undefined {
    return this._date;
  }
  set date(value: googleProtobuf001.Timestamp | undefined) {
    this._date = value;
  }
  get goals(): GetMeActivity.Goal[] | undefined {
    return this._goals;
  }
  set goals(value: GetMeActivity.Goal[] | undefined) {
    this._goals = value;
  }
  get type(): goal_transaction_enums002.TransactionRequestType {
    return this._type;
  }
  set type(value: goal_transaction_enums002.TransactionRequestType) {
    this._type = value;
  }
  get description(): string {
    return this._description;
  }
  set description(value: string) {
    this._description = value;
  }
  get amount(): string {
    return this._amount;
  }
  set amount(value: string) {
    this._amount = value;
  }
  get isPositive(): boolean {
    return this._isPositive;
  }
  set isPositive(value: boolean) {
    this._isPositive = value;
  }
  get isProcessed(): boolean {
    return this._isProcessed;
  }
  set isProcessed(value: boolean) {
    this._isProcessed = value;
  }
  get isPartial(): boolean {
    return this._isPartial;
  }
  set isPartial(value: boolean) {
    this._isPartial = value;
  }
  get descriptionTooltip(): string {
    return this._descriptionTooltip;
  }
  set descriptionTooltip(value: string) {
    this._descriptionTooltip = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetMeActivity.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetMeActivity.AsObject {
    return {
      requestId: this.requestId ? this.requestId.toObject() : undefined,
      goalTransactionId: this.goalTransactionId
        ? this.goalTransactionId.toObject()
        : undefined,
      date: this.date ? this.date.toObject() : undefined,
      goals: (this.goals || []).map(m => m.toObject()),
      type: this.type,
      description: this.description,
      amount: this.amount,
      isPositive: this.isPositive,
      isProcessed: this.isProcessed,
      isPartial: this.isPartial,
      descriptionTooltip: this.descriptionTooltip
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetMeActivity.AsProtobufJSON {
    return {
      requestId: this.requestId ? this.requestId.toProtobufJSON(options) : null,
      goalTransactionId: this.goalTransactionId
        ? this.goalTransactionId.toProtobufJSON(options)
        : null,
      date: this.date ? this.date.toProtobufJSON(options) : null,
      goals: (this.goals || []).map(m => m.toProtobufJSON(options)),
      type:
        goal_transaction_enums002.TransactionRequestType[
          this.type === null || this.type === undefined ? 0 : this.type
        ],
      description: this.description,
      amount: this.amount,
      isPositive: this.isPositive,
      isProcessed: this.isProcessed,
      isPartial: this.isPartial,
      descriptionTooltip: this.descriptionTooltip
    };
  }
}
export module GetMeActivity {
  /**
   * Standard JavaScript object representation for GetMeActivity
   */
  export interface AsObject {
    requestId?: googleProtobuf000.Int32Value.AsObject;
    goalTransactionId?: googleProtobuf000.Int32Value.AsObject;
    date?: googleProtobuf001.Timestamp.AsObject;
    goals?: GetMeActivity.Goal.AsObject[];
    type: goal_transaction_enums002.TransactionRequestType;
    description: string;
    amount: string;
    isPositive: boolean;
    isProcessed: boolean;
    isPartial: boolean;
    descriptionTooltip: string;
  }

  /**
   * Protobuf JSON representation for GetMeActivity
   */
  export interface AsProtobufJSON {
    requestId: googleProtobuf000.Int32Value.AsProtobufJSON | null;
    goalTransactionId: googleProtobuf000.Int32Value.AsProtobufJSON | null;
    date: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    goals: GetMeActivity.Goal.AsProtobufJSON[] | null;
    type: string;
    description: string;
    amount: string;
    isPositive: boolean;
    isProcessed: boolean;
    isPartial: boolean;
    descriptionTooltip: string;
  }

  /**
   * Message implementation for portal_user.GetMeActivity.Goal
   */
  export class Goal implements GrpcMessage {
    static id = 'portal_user.GetMeActivity.Goal';

    /**
     * Deserialize binary data to message
     * @param instance message instance
     */
    static deserializeBinary(bytes: ByteSource) {
      const instance = new Goal();
      Goal.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
      return instance;
    }

    /**
     * Check all the properties and set default protobuf values if necessary
     * @param _instance message instance
     */
    static refineValues(_instance: Goal) {
      _instance.id = _instance.id || 0;
      _instance.title = _instance.title || '';
      _instance.imageSmall = _instance.imageSmall || '';
      _instance.imageLarge = _instance.imageLarge || '';
    }

    /**
     * Deserializes / reads binary message into message instance using provided binary reader
     * @param _instance message instance
     * @param _reader binary reader instance
     */
    static deserializeBinaryFromReader(_instance: Goal, _reader: BinaryReader) {
      while (_reader.nextField()) {
        if (_reader.isEndGroup()) break;

        switch (_reader.getFieldNumber()) {
          case 1:
            _instance.id = _reader.readInt32();
            break;
          case 2:
            _instance.title = _reader.readString();
            break;
          case 3:
            _instance.imageSmall = _reader.readString();
            break;
          case 4:
            _instance.imageLarge = _reader.readString();
            break;
          default:
            _reader.skipField();
        }
      }

      Goal.refineValues(_instance);
    }

    /**
     * Serializes a message to binary format using provided binary reader
     * @param _instance message instance
     * @param _writer binary writer instance
     */
    static serializeBinaryToWriter(_instance: Goal, _writer: BinaryWriter) {
      if (_instance.id) {
        _writer.writeInt32(1, _instance.id);
      }
      if (_instance.title) {
        _writer.writeString(2, _instance.title);
      }
      if (_instance.imageSmall) {
        _writer.writeString(3, _instance.imageSmall);
      }
      if (_instance.imageLarge) {
        _writer.writeString(4, _instance.imageLarge);
      }
    }

    private _id: number;
    private _title: string;
    private _imageSmall: string;
    private _imageLarge: string;

    /**
     * Message constructor. Initializes the properties and applies default Protobuf values if necessary
     * @param _value initial values object or instance of Goal to deeply clone from
     */
    constructor(_value?: RecursivePartial<Goal.AsObject>) {
      _value = _value || {};
      this.id = _value.id;
      this.title = _value.title;
      this.imageSmall = _value.imageSmall;
      this.imageLarge = _value.imageLarge;
      Goal.refineValues(this);
    }
    get id(): number {
      return this._id;
    }
    set id(value: number) {
      this._id = value;
    }
    get title(): string {
      return this._title;
    }
    set title(value: string) {
      this._title = value;
    }
    get imageSmall(): string {
      return this._imageSmall;
    }
    set imageSmall(value: string) {
      this._imageSmall = value;
    }
    get imageLarge(): string {
      return this._imageLarge;
    }
    set imageLarge(value: string) {
      this._imageLarge = value;
    }

    /**
     * Serialize message to binary data
     * @param instance message instance
     */
    serializeBinary() {
      const writer = new BinaryWriter();
      Goal.serializeBinaryToWriter(this, writer);
      return writer.getResultBuffer();
    }

    /**
     * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
     */
    toObject(): Goal.AsObject {
      return {
        id: this.id,
        title: this.title,
        imageSmall: this.imageSmall,
        imageLarge: this.imageLarge
      };
    }

    /**
     * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
     */
    toJSON() {
      return this.toObject();
    }

    /**
     * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
     * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
     * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
     */
    toProtobufJSON(
      // @ts-ignore
      options?: ToProtobufJSONOptions
    ): Goal.AsProtobufJSON {
      return {
        id: this.id,
        title: this.title,
        imageSmall: this.imageSmall,
        imageLarge: this.imageLarge
      };
    }
  }
  export module Goal {
    /**
     * Standard JavaScript object representation for Goal
     */
    export interface AsObject {
      id: number;
      title: string;
      imageSmall: string;
      imageLarge: string;
    }

    /**
     * Protobuf JSON representation for Goal
     */
    export interface AsProtobufJSON {
      id: number;
      title: string;
      imageSmall: string;
      imageLarge: string;
    }
  }
}

/**
 * Message implementation for portal_user.CancelTransactionRequestRequest
 */
export class CancelTransactionRequestRequest implements GrpcMessage {
  static id = 'portal_user.CancelTransactionRequestRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new CancelTransactionRequestRequest();
    CancelTransactionRequestRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: CancelTransactionRequestRequest) {
    _instance.id = _instance.id || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: CancelTransactionRequestRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    CancelTransactionRequestRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: CancelTransactionRequestRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeInt32(1, _instance.id);
    }
  }

  private _id: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of CancelTransactionRequestRequest to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<CancelTransactionRequestRequest.AsObject>
  ) {
    _value = _value || {};
    this.id = _value.id;
    CancelTransactionRequestRequest.refineValues(this);
  }
  get id(): number {
    return this._id;
  }
  set id(value: number) {
    this._id = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    CancelTransactionRequestRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): CancelTransactionRequestRequest.AsObject {
    return {
      id: this.id
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): CancelTransactionRequestRequest.AsProtobufJSON {
    return {
      id: this.id
    };
  }
}
export module CancelTransactionRequestRequest {
  /**
   * Standard JavaScript object representation for CancelTransactionRequestRequest
   */
  export interface AsObject {
    id: number;
  }

  /**
   * Protobuf JSON representation for CancelTransactionRequestRequest
   */
  export interface AsProtobufJSON {
    id: number;
  }
}

/**
 * Message implementation for portal_user.CancelTransactionRequestResponse
 */
export class CancelTransactionRequestResponse implements GrpcMessage {
  static id = 'portal_user.CancelTransactionRequestResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new CancelTransactionRequestResponse();
    CancelTransactionRequestResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: CancelTransactionRequestResponse) {
    _instance.success = _instance.success || false;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: CancelTransactionRequestResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.success = _reader.readBool();
          break;
        default:
          _reader.skipField();
      }
    }

    CancelTransactionRequestResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: CancelTransactionRequestResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.success) {
      _writer.writeBool(1, _instance.success);
    }
  }

  private _success: boolean;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of CancelTransactionRequestResponse to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<CancelTransactionRequestResponse.AsObject>
  ) {
    _value = _value || {};
    this.success = _value.success;
    CancelTransactionRequestResponse.refineValues(this);
  }
  get success(): boolean {
    return this._success;
  }
  set success(value: boolean) {
    this._success = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    CancelTransactionRequestResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): CancelTransactionRequestResponse.AsObject {
    return {
      success: this.success
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): CancelTransactionRequestResponse.AsProtobufJSON {
    return {
      success: this.success
    };
  }
}
export module CancelTransactionRequestResponse {
  /**
   * Standard JavaScript object representation for CancelTransactionRequestResponse
   */
  export interface AsObject {
    success: boolean;
  }

  /**
   * Protobuf JSON representation for CancelTransactionRequestResponse
   */
  export interface AsProtobufJSON {
    success: boolean;
  }
}

/**
 * Message implementation for portal_user.GetMeNotificationsTransactionsRequest
 */
export class GetMeNotificationsTransactionsRequest implements GrpcMessage {
  static id = 'portal_user.GetMeNotificationsTransactionsRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetMeNotificationsTransactionsRequest();
    GetMeNotificationsTransactionsRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetMeNotificationsTransactionsRequest) {
    _instance.goalId = _instance.goalId || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetMeNotificationsTransactionsRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 5:
          _instance.goalId = new googleProtobuf000.Int32Value();
          _reader.readMessage(
            _instance.goalId,
            googleProtobuf000.Int32Value.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    GetMeNotificationsTransactionsRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetMeNotificationsTransactionsRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.goalId) {
      _writer.writeMessage(
        5,
        _instance.goalId as any,
        googleProtobuf000.Int32Value.serializeBinaryToWriter
      );
    }
  }

  private _goalId?: googleProtobuf000.Int32Value;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetMeNotificationsTransactionsRequest to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<GetMeNotificationsTransactionsRequest.AsObject>
  ) {
    _value = _value || {};
    this.goalId = _value.goalId
      ? new googleProtobuf000.Int32Value(_value.goalId)
      : undefined;
    GetMeNotificationsTransactionsRequest.refineValues(this);
  }
  get goalId(): googleProtobuf000.Int32Value | undefined {
    return this._goalId;
  }
  set goalId(value: googleProtobuf000.Int32Value | undefined) {
    this._goalId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetMeNotificationsTransactionsRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetMeNotificationsTransactionsRequest.AsObject {
    return {
      goalId: this.goalId ? this.goalId.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetMeNotificationsTransactionsRequest.AsProtobufJSON {
    return {
      goalId: this.goalId ? this.goalId.toProtobufJSON(options) : null
    };
  }
}
export module GetMeNotificationsTransactionsRequest {
  /**
   * Standard JavaScript object representation for GetMeNotificationsTransactionsRequest
   */
  export interface AsObject {
    goalId?: googleProtobuf000.Int32Value.AsObject;
  }

  /**
   * Protobuf JSON representation for GetMeNotificationsTransactionsRequest
   */
  export interface AsProtobufJSON {
    goalId: googleProtobuf000.Int32Value.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for portal_user.GetMeNotificationsTransactionsPendingResponse
 */
export class GetMeNotificationsTransactionsPendingResponse
  implements GrpcMessage {
  static id = 'portal_user.GetMeNotificationsTransactionsPendingResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetMeNotificationsTransactionsPendingResponse();
    GetMeNotificationsTransactionsPendingResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(
    _instance: GetMeNotificationsTransactionsPendingResponse
  ) {
    _instance.values = _instance.values || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetMeNotificationsTransactionsPendingResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new NotificationsTransactionsPending();
          _reader.readMessage(
            messageInitializer1,
            NotificationsTransactionsPending.deserializeBinaryFromReader
          );
          (_instance.values = _instance.values || []).push(messageInitializer1);
          break;
        default:
          _reader.skipField();
      }
    }

    GetMeNotificationsTransactionsPendingResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetMeNotificationsTransactionsPendingResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.values && _instance.values.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.values as any,
        NotificationsTransactionsPending.serializeBinaryToWriter
      );
    }
  }

  private _values?: NotificationsTransactionsPending[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetMeNotificationsTransactionsPendingResponse to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<
      GetMeNotificationsTransactionsPendingResponse.AsObject
    >
  ) {
    _value = _value || {};
    this.values = (_value.values || []).map(
      m => new NotificationsTransactionsPending(m)
    );
    GetMeNotificationsTransactionsPendingResponse.refineValues(this);
  }
  get values(): NotificationsTransactionsPending[] | undefined {
    return this._values;
  }
  set values(value: NotificationsTransactionsPending[] | undefined) {
    this._values = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetMeNotificationsTransactionsPendingResponse.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetMeNotificationsTransactionsPendingResponse.AsObject {
    return {
      values: (this.values || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetMeNotificationsTransactionsPendingResponse.AsProtobufJSON {
    return {
      values: (this.values || []).map(m => m.toProtobufJSON(options))
    };
  }
}
export module GetMeNotificationsTransactionsPendingResponse {
  /**
   * Standard JavaScript object representation for GetMeNotificationsTransactionsPendingResponse
   */
  export interface AsObject {
    values?: NotificationsTransactionsPending.AsObject[];
  }

  /**
   * Protobuf JSON representation for GetMeNotificationsTransactionsPendingResponse
   */
  export interface AsProtobufJSON {
    values: NotificationsTransactionsPending.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for portal_user.NotificationsTransactionsPending
 */
export class NotificationsTransactionsPending implements GrpcMessage {
  static id = 'portal_user.NotificationsTransactionsPending';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new NotificationsTransactionsPending();
    NotificationsTransactionsPending.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: NotificationsTransactionsPending) {
    _instance.type = _instance.type || 0;
    _instance.title = _instance.title || '';
    _instance.description = _instance.description || '';
    _instance.goalTransactionId = _instance.goalTransactionId || undefined;
    _instance.goalId = _instance.goalId || undefined;
    _instance.transactionRequestId = _instance.transactionRequestId || 0;
    _instance.goalPhoto = _instance.goalPhoto || undefined;
    _instance.transactionRequestUuid =
      _instance.transactionRequestUuid || undefined;
    _instance.canBeCanceled = _instance.canBeCanceled || false;
    _instance.canBeReassigned = _instance.canBeReassigned || false;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: NotificationsTransactionsPending,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.type = _reader.readEnum();
          break;
        case 2:
          _instance.title = _reader.readString();
          break;
        case 3:
          _instance.description = _reader.readString();
          break;
        case 4:
          _instance.goalTransactionId = new googleProtobuf000.Int32Value();
          _reader.readMessage(
            _instance.goalTransactionId,
            googleProtobuf000.Int32Value.deserializeBinaryFromReader
          );
          break;
        case 5:
          _instance.goalId = new googleProtobuf000.Int32Value();
          _reader.readMessage(
            _instance.goalId,
            googleProtobuf000.Int32Value.deserializeBinaryFromReader
          );
          break;
        case 6:
          _instance.transactionRequestId = _reader.readInt32();
          break;
        case 7:
          _instance.goalPhoto = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.goalPhoto,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 8:
          _instance.transactionRequestUuid = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.transactionRequestUuid,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 9:
          _instance.canBeCanceled = _reader.readBool();
          break;
        case 10:
          _instance.canBeReassigned = _reader.readBool();
          break;
        default:
          _reader.skipField();
      }
    }

    NotificationsTransactionsPending.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: NotificationsTransactionsPending,
    _writer: BinaryWriter
  ) {
    if (_instance.type) {
      _writer.writeEnum(1, _instance.type);
    }
    if (_instance.title) {
      _writer.writeString(2, _instance.title);
    }
    if (_instance.description) {
      _writer.writeString(3, _instance.description);
    }
    if (_instance.goalTransactionId) {
      _writer.writeMessage(
        4,
        _instance.goalTransactionId as any,
        googleProtobuf000.Int32Value.serializeBinaryToWriter
      );
    }
    if (_instance.goalId) {
      _writer.writeMessage(
        5,
        _instance.goalId as any,
        googleProtobuf000.Int32Value.serializeBinaryToWriter
      );
    }
    if (_instance.transactionRequestId) {
      _writer.writeInt32(6, _instance.transactionRequestId);
    }
    if (_instance.goalPhoto) {
      _writer.writeMessage(
        7,
        _instance.goalPhoto as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.transactionRequestUuid) {
      _writer.writeMessage(
        8,
        _instance.transactionRequestUuid as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.canBeCanceled) {
      _writer.writeBool(9, _instance.canBeCanceled);
    }
    if (_instance.canBeReassigned) {
      _writer.writeBool(10, _instance.canBeReassigned);
    }
  }

  private _type: NotificationTransactionPendingTypeEnum;
  private _title: string;
  private _description: string;
  private _goalTransactionId?: googleProtobuf000.Int32Value;
  private _goalId?: googleProtobuf000.Int32Value;
  private _transactionRequestId: number;
  private _goalPhoto?: googleProtobuf000.StringValue;
  private _transactionRequestUuid?: googleProtobuf000.StringValue;
  private _canBeCanceled: boolean;
  private _canBeReassigned: boolean;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of NotificationsTransactionsPending to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<NotificationsTransactionsPending.AsObject>
  ) {
    _value = _value || {};
    this.type = _value.type;
    this.title = _value.title;
    this.description = _value.description;
    this.goalTransactionId = _value.goalTransactionId
      ? new googleProtobuf000.Int32Value(_value.goalTransactionId)
      : undefined;
    this.goalId = _value.goalId
      ? new googleProtobuf000.Int32Value(_value.goalId)
      : undefined;
    this.transactionRequestId = _value.transactionRequestId;
    this.goalPhoto = _value.goalPhoto
      ? new googleProtobuf000.StringValue(_value.goalPhoto)
      : undefined;
    this.transactionRequestUuid = _value.transactionRequestUuid
      ? new googleProtobuf000.StringValue(_value.transactionRequestUuid)
      : undefined;
    this.canBeCanceled = _value.canBeCanceled;
    this.canBeReassigned = _value.canBeReassigned;
    NotificationsTransactionsPending.refineValues(this);
  }
  get type(): NotificationTransactionPendingTypeEnum {
    return this._type;
  }
  set type(value: NotificationTransactionPendingTypeEnum) {
    this._type = value;
  }
  get title(): string {
    return this._title;
  }
  set title(value: string) {
    this._title = value;
  }
  get description(): string {
    return this._description;
  }
  set description(value: string) {
    this._description = value;
  }
  get goalTransactionId(): googleProtobuf000.Int32Value | undefined {
    return this._goalTransactionId;
  }
  set goalTransactionId(value: googleProtobuf000.Int32Value | undefined) {
    this._goalTransactionId = value;
  }
  get goalId(): googleProtobuf000.Int32Value | undefined {
    return this._goalId;
  }
  set goalId(value: googleProtobuf000.Int32Value | undefined) {
    this._goalId = value;
  }
  get transactionRequestId(): number {
    return this._transactionRequestId;
  }
  set transactionRequestId(value: number) {
    this._transactionRequestId = value;
  }
  get goalPhoto(): googleProtobuf000.StringValue | undefined {
    return this._goalPhoto;
  }
  set goalPhoto(value: googleProtobuf000.StringValue | undefined) {
    this._goalPhoto = value;
  }
  get transactionRequestUuid(): googleProtobuf000.StringValue | undefined {
    return this._transactionRequestUuid;
  }
  set transactionRequestUuid(value: googleProtobuf000.StringValue | undefined) {
    this._transactionRequestUuid = value;
  }
  get canBeCanceled(): boolean {
    return this._canBeCanceled;
  }
  set canBeCanceled(value: boolean) {
    this._canBeCanceled = value;
  }
  get canBeReassigned(): boolean {
    return this._canBeReassigned;
  }
  set canBeReassigned(value: boolean) {
    this._canBeReassigned = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    NotificationsTransactionsPending.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): NotificationsTransactionsPending.AsObject {
    return {
      type: this.type,
      title: this.title,
      description: this.description,
      goalTransactionId: this.goalTransactionId
        ? this.goalTransactionId.toObject()
        : undefined,
      goalId: this.goalId ? this.goalId.toObject() : undefined,
      transactionRequestId: this.transactionRequestId,
      goalPhoto: this.goalPhoto ? this.goalPhoto.toObject() : undefined,
      transactionRequestUuid: this.transactionRequestUuid
        ? this.transactionRequestUuid.toObject()
        : undefined,
      canBeCanceled: this.canBeCanceled,
      canBeReassigned: this.canBeReassigned
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): NotificationsTransactionsPending.AsProtobufJSON {
    return {
      type:
        NotificationTransactionPendingTypeEnum[
          this.type === null || this.type === undefined ? 0 : this.type
        ],
      title: this.title,
      description: this.description,
      goalTransactionId: this.goalTransactionId
        ? this.goalTransactionId.toProtobufJSON(options)
        : null,
      goalId: this.goalId ? this.goalId.toProtobufJSON(options) : null,
      transactionRequestId: this.transactionRequestId,
      goalPhoto: this.goalPhoto ? this.goalPhoto.toProtobufJSON(options) : null,
      transactionRequestUuid: this.transactionRequestUuid
        ? this.transactionRequestUuid.toProtobufJSON(options)
        : null,
      canBeCanceled: this.canBeCanceled,
      canBeReassigned: this.canBeReassigned
    };
  }
}
export module NotificationsTransactionsPending {
  /**
   * Standard JavaScript object representation for NotificationsTransactionsPending
   */
  export interface AsObject {
    type: NotificationTransactionPendingTypeEnum;
    title: string;
    description: string;
    goalTransactionId?: googleProtobuf000.Int32Value.AsObject;
    goalId?: googleProtobuf000.Int32Value.AsObject;
    transactionRequestId: number;
    goalPhoto?: googleProtobuf000.StringValue.AsObject;
    transactionRequestUuid?: googleProtobuf000.StringValue.AsObject;
    canBeCanceled: boolean;
    canBeReassigned: boolean;
  }

  /**
   * Protobuf JSON representation for NotificationsTransactionsPending
   */
  export interface AsProtobufJSON {
    type: string;
    title: string;
    description: string;
    goalTransactionId: googleProtobuf000.Int32Value.AsProtobufJSON | null;
    goalId: googleProtobuf000.Int32Value.AsProtobufJSON | null;
    transactionRequestId: number;
    goalPhoto: googleProtobuf000.StringValue.AsProtobufJSON | null;
    transactionRequestUuid: googleProtobuf000.StringValue.AsProtobufJSON | null;
    canBeCanceled: boolean;
    canBeReassigned: boolean;
  }
}

/**
 * Message implementation for portal_user.GetMeSummaryNotificationsTransactionsPendingResponse
 */
export class GetMeSummaryNotificationsTransactionsPendingResponse
  implements GrpcMessage {
  static id =
    'portal_user.GetMeSummaryNotificationsTransactionsPendingResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetMeSummaryNotificationsTransactionsPendingResponse();
    GetMeSummaryNotificationsTransactionsPendingResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(
    _instance: GetMeSummaryNotificationsTransactionsPendingResponse
  ) {
    _instance.values = _instance.values || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetMeSummaryNotificationsTransactionsPendingResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new SummaryNotificationsTransactionsPending();
          _reader.readMessage(
            messageInitializer1,
            SummaryNotificationsTransactionsPending.deserializeBinaryFromReader
          );
          (_instance.values = _instance.values || []).push(messageInitializer1);
          break;
        default:
          _reader.skipField();
      }
    }

    GetMeSummaryNotificationsTransactionsPendingResponse.refineValues(
      _instance
    );
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetMeSummaryNotificationsTransactionsPendingResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.values && _instance.values.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.values as any,
        SummaryNotificationsTransactionsPending.serializeBinaryToWriter
      );
    }
  }

  private _values?: SummaryNotificationsTransactionsPending[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetMeSummaryNotificationsTransactionsPendingResponse to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<
      GetMeSummaryNotificationsTransactionsPendingResponse.AsObject
    >
  ) {
    _value = _value || {};
    this.values = (_value.values || []).map(
      m => new SummaryNotificationsTransactionsPending(m)
    );
    GetMeSummaryNotificationsTransactionsPendingResponse.refineValues(this);
  }
  get values(): SummaryNotificationsTransactionsPending[] | undefined {
    return this._values;
  }
  set values(value: SummaryNotificationsTransactionsPending[] | undefined) {
    this._values = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetMeSummaryNotificationsTransactionsPendingResponse.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetMeSummaryNotificationsTransactionsPendingResponse.AsObject {
    return {
      values: (this.values || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetMeSummaryNotificationsTransactionsPendingResponse.AsProtobufJSON {
    return {
      values: (this.values || []).map(m => m.toProtobufJSON(options))
    };
  }
}
export module GetMeSummaryNotificationsTransactionsPendingResponse {
  /**
   * Standard JavaScript object representation for GetMeSummaryNotificationsTransactionsPendingResponse
   */
  export interface AsObject {
    values?: SummaryNotificationsTransactionsPending.AsObject[];
  }

  /**
   * Protobuf JSON representation for GetMeSummaryNotificationsTransactionsPendingResponse
   */
  export interface AsProtobufJSON {
    values: SummaryNotificationsTransactionsPending.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for portal_user.SummaryNotificationsTransactionsPending
 */
export class SummaryNotificationsTransactionsPending implements GrpcMessage {
  static id = 'portal_user.SummaryNotificationsTransactionsPending';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new SummaryNotificationsTransactionsPending();
    SummaryNotificationsTransactionsPending.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: SummaryNotificationsTransactionsPending) {
    _instance.type = _instance.type || 0;
    _instance.title = _instance.title || '';
    _instance.description = _instance.description || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: SummaryNotificationsTransactionsPending,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.type = _reader.readEnum();
          break;
        case 2:
          _instance.title = _reader.readString();
          break;
        case 3:
          _instance.description = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    SummaryNotificationsTransactionsPending.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: SummaryNotificationsTransactionsPending,
    _writer: BinaryWriter
  ) {
    if (_instance.type) {
      _writer.writeEnum(1, _instance.type);
    }
    if (_instance.title) {
      _writer.writeString(2, _instance.title);
    }
    if (_instance.description) {
      _writer.writeString(3, _instance.description);
    }
  }

  private _type: NotificationTransactionPendingTypeEnum;
  private _title: string;
  private _description: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of SummaryNotificationsTransactionsPending to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<SummaryNotificationsTransactionsPending.AsObject>
  ) {
    _value = _value || {};
    this.type = _value.type;
    this.title = _value.title;
    this.description = _value.description;
    SummaryNotificationsTransactionsPending.refineValues(this);
  }
  get type(): NotificationTransactionPendingTypeEnum {
    return this._type;
  }
  set type(value: NotificationTransactionPendingTypeEnum) {
    this._type = value;
  }
  get title(): string {
    return this._title;
  }
  set title(value: string) {
    this._title = value;
  }
  get description(): string {
    return this._description;
  }
  set description(value: string) {
    this._description = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    SummaryNotificationsTransactionsPending.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): SummaryNotificationsTransactionsPending.AsObject {
    return {
      type: this.type,
      title: this.title,
      description: this.description
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): SummaryNotificationsTransactionsPending.AsProtobufJSON {
    return {
      type:
        NotificationTransactionPendingTypeEnum[
          this.type === null || this.type === undefined ? 0 : this.type
        ],
      title: this.title,
      description: this.description
    };
  }
}
export module SummaryNotificationsTransactionsPending {
  /**
   * Standard JavaScript object representation for SummaryNotificationsTransactionsPending
   */
  export interface AsObject {
    type: NotificationTransactionPendingTypeEnum;
    title: string;
    description: string;
  }

  /**
   * Protobuf JSON representation for SummaryNotificationsTransactionsPending
   */
  export interface AsProtobufJSON {
    type: string;
    title: string;
    description: string;
  }
}

/**
 * Message implementation for portal_user.UploadCIRequest
 */
export class UploadCIRequest implements GrpcMessage {
  static id = 'portal_user.UploadCIRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UploadCIRequest();
    UploadCIRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UploadCIRequest) {
    _instance.fileCiFront = _instance.fileCiFront || undefined;
    _instance.fileCiBack = _instance.fileCiBack || undefined;
    _instance.country = _instance.country || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UploadCIRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.fileCiFront = new file_message007.FileModel();
          _reader.readMessage(
            _instance.fileCiFront,
            file_message007.FileModel.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.fileCiBack = new file_message007.FileModel();
          _reader.readMessage(
            _instance.fileCiBack,
            file_message007.FileModel.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.country = _reader.readEnum();
          break;
        default:
          _reader.skipField();
      }
    }

    UploadCIRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: UploadCIRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.fileCiFront) {
      _writer.writeMessage(
        1,
        _instance.fileCiFront as any,
        file_message007.FileModel.serializeBinaryToWriter
      );
    }
    if (_instance.fileCiBack) {
      _writer.writeMessage(
        2,
        _instance.fileCiBack as any,
        file_message007.FileModel.serializeBinaryToWriter
      );
    }
    if (_instance.country) {
      _writer.writeEnum(3, _instance.country);
    }
  }

  private _fileCiFront?: file_message007.FileModel;
  private _fileCiBack?: file_message007.FileModel;
  private _country: document_country_enums009.DocumentCountry;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UploadCIRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<UploadCIRequest.AsObject>) {
    _value = _value || {};
    this.fileCiFront = _value.fileCiFront
      ? new file_message007.FileModel(_value.fileCiFront)
      : undefined;
    this.fileCiBack = _value.fileCiBack
      ? new file_message007.FileModel(_value.fileCiBack)
      : undefined;
    this.country = _value.country;
    UploadCIRequest.refineValues(this);
  }
  get fileCiFront(): file_message007.FileModel | undefined {
    return this._fileCiFront;
  }
  set fileCiFront(value: file_message007.FileModel | undefined) {
    this._fileCiFront = value;
  }
  get fileCiBack(): file_message007.FileModel | undefined {
    return this._fileCiBack;
  }
  set fileCiBack(value: file_message007.FileModel | undefined) {
    this._fileCiBack = value;
  }
  get country(): document_country_enums009.DocumentCountry {
    return this._country;
  }
  set country(value: document_country_enums009.DocumentCountry) {
    this._country = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UploadCIRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UploadCIRequest.AsObject {
    return {
      fileCiFront: this.fileCiFront ? this.fileCiFront.toObject() : undefined,
      fileCiBack: this.fileCiBack ? this.fileCiBack.toObject() : undefined,
      country: this.country
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UploadCIRequest.AsProtobufJSON {
    return {
      fileCiFront: this.fileCiFront
        ? this.fileCiFront.toProtobufJSON(options)
        : null,
      fileCiBack: this.fileCiBack
        ? this.fileCiBack.toProtobufJSON(options)
        : null,
      country:
        document_country_enums009.DocumentCountry[
          this.country === null || this.country === undefined ? 0 : this.country
        ]
    };
  }
}
export module UploadCIRequest {
  /**
   * Standard JavaScript object representation for UploadCIRequest
   */
  export interface AsObject {
    fileCiFront?: file_message007.FileModel.AsObject;
    fileCiBack?: file_message007.FileModel.AsObject;
    country: document_country_enums009.DocumentCountry;
  }

  /**
   * Protobuf JSON representation for UploadCIRequest
   */
  export interface AsProtobufJSON {
    fileCiFront: file_message007.FileModel.AsProtobufJSON | null;
    fileCiBack: file_message007.FileModel.AsProtobufJSON | null;
    country: string;
  }
}

/**
 * Message implementation for portal_user.UploadCIResponse
 */
export class UploadCIResponse implements GrpcMessage {
  static id = 'portal_user.UploadCIResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UploadCIResponse();
    UploadCIResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UploadCIResponse) {
    _instance.status = _instance.status || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UploadCIResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.status = _reader.readEnum();
          break;
        default:
          _reader.skipField();
      }
    }

    UploadCIResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: UploadCIResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.status) {
      _writer.writeEnum(1, _instance.status);
    }
  }

  private _status: common_message008.UploadCIStatusEnum;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UploadCIResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<UploadCIResponse.AsObject>) {
    _value = _value || {};
    this.status = _value.status;
    UploadCIResponse.refineValues(this);
  }
  get status(): common_message008.UploadCIStatusEnum {
    return this._status;
  }
  set status(value: common_message008.UploadCIStatusEnum) {
    this._status = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UploadCIResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UploadCIResponse.AsObject {
    return {
      status: this.status
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UploadCIResponse.AsProtobufJSON {
    return {
      status:
        common_message008.UploadCIStatusEnum[
          this.status === null || this.status === undefined ? 0 : this.status
        ]
    };
  }
}
export module UploadCIResponse {
  /**
   * Standard JavaScript object representation for UploadCIResponse
   */
  export interface AsObject {
    status: common_message008.UploadCIStatusEnum;
  }

  /**
   * Protobuf JSON representation for UploadCIResponse
   */
  export interface AsProtobufJSON {
    status: string;
  }
}

/**
 * Message implementation for portal_user.GetMeFinancialEntitiesResponse
 */
export class GetMeFinancialEntitiesResponse implements GrpcMessage {
  static id = 'portal_user.GetMeFinancialEntitiesResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetMeFinancialEntitiesResponse();
    GetMeFinancialEntitiesResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetMeFinancialEntitiesResponse) {
    _instance.values = _instance.values || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetMeFinancialEntitiesResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new MeFinancialEntityModel();
          _reader.readMessage(
            messageInitializer1,
            MeFinancialEntityModel.deserializeBinaryFromReader
          );
          (_instance.values = _instance.values || []).push(messageInitializer1);
          break;
        default:
          _reader.skipField();
      }
    }

    GetMeFinancialEntitiesResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetMeFinancialEntitiesResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.values && _instance.values.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.values as any,
        MeFinancialEntityModel.serializeBinaryToWriter
      );
    }
  }

  private _values?: MeFinancialEntityModel[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetMeFinancialEntitiesResponse to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<GetMeFinancialEntitiesResponse.AsObject>
  ) {
    _value = _value || {};
    this.values = (_value.values || []).map(m => new MeFinancialEntityModel(m));
    GetMeFinancialEntitiesResponse.refineValues(this);
  }
  get values(): MeFinancialEntityModel[] | undefined {
    return this._values;
  }
  set values(value: MeFinancialEntityModel[] | undefined) {
    this._values = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetMeFinancialEntitiesResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetMeFinancialEntitiesResponse.AsObject {
    return {
      values: (this.values || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetMeFinancialEntitiesResponse.AsProtobufJSON {
    return {
      values: (this.values || []).map(m => m.toProtobufJSON(options))
    };
  }
}
export module GetMeFinancialEntitiesResponse {
  /**
   * Standard JavaScript object representation for GetMeFinancialEntitiesResponse
   */
  export interface AsObject {
    values?: MeFinancialEntityModel.AsObject[];
  }

  /**
   * Protobuf JSON representation for GetMeFinancialEntitiesResponse
   */
  export interface AsProtobufJSON {
    values: MeFinancialEntityModel.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for portal_user.ListCommuneWithRegionResponse
 */
export class ListCommuneWithRegionResponse implements GrpcMessage {
  static id = 'portal_user.ListCommuneWithRegionResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ListCommuneWithRegionResponse();
    ListCommuneWithRegionResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ListCommuneWithRegionResponse) {
    _instance.values = _instance.values || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ListCommuneWithRegionResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new common_message008.CommuneModel();
          _reader.readMessage(
            messageInitializer1,
            common_message008.CommuneModel.deserializeBinaryFromReader
          );
          (_instance.values = _instance.values || []).push(messageInitializer1);
          break;
        default:
          _reader.skipField();
      }
    }

    ListCommuneWithRegionResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ListCommuneWithRegionResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.values && _instance.values.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.values as any,
        common_message008.CommuneModel.serializeBinaryToWriter
      );
    }
  }

  private _values?: common_message008.CommuneModel[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ListCommuneWithRegionResponse to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<ListCommuneWithRegionResponse.AsObject>
  ) {
    _value = _value || {};
    this.values = (_value.values || []).map(
      m => new common_message008.CommuneModel(m)
    );
    ListCommuneWithRegionResponse.refineValues(this);
  }
  get values(): common_message008.CommuneModel[] | undefined {
    return this._values;
  }
  set values(value: common_message008.CommuneModel[] | undefined) {
    this._values = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ListCommuneWithRegionResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ListCommuneWithRegionResponse.AsObject {
    return {
      values: (this.values || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ListCommuneWithRegionResponse.AsProtobufJSON {
    return {
      values: (this.values || []).map(m => m.toProtobufJSON(options))
    };
  }
}
export module ListCommuneWithRegionResponse {
  /**
   * Standard JavaScript object representation for ListCommuneWithRegionResponse
   */
  export interface AsObject {
    values?: common_message008.CommuneModel.AsObject[];
  }

  /**
   * Protobuf JSON representation for ListCommuneWithRegionResponse
   */
  export interface AsProtobufJSON {
    values: common_message008.CommuneModel.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for portal_user.ContractSigningRequest
 */
export class ContractSigningRequest implements GrpcMessage {
  static id = 'portal_user.ContractSigningRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ContractSigningRequest();
    ContractSigningRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ContractSigningRequest) {
    _instance.financeEntityId = _instance.financeEntityId || 0;
    _instance.signature = _instance.signature || '';
    _instance.userBankAccount = _instance.userBankAccount || undefined;
    _instance.responses = _instance.responses || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ContractSigningRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.financeEntityId = _reader.readInt32();
          break;
        case 2:
          _instance.signature = _reader.readString();
          break;
        case 3:
          _instance.userBankAccount = new common_message008.UserBankAccountModel();
          _reader.readMessage(
            _instance.userBankAccount,
            common_message008.UserBankAccountModel.deserializeBinaryFromReader
          );
          break;
        case 4:
          _instance.responses = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    ContractSigningRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ContractSigningRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.financeEntityId) {
      _writer.writeInt32(1, _instance.financeEntityId);
    }
    if (_instance.signature) {
      _writer.writeString(2, _instance.signature);
    }
    if (_instance.userBankAccount) {
      _writer.writeMessage(
        3,
        _instance.userBankAccount as any,
        common_message008.UserBankAccountModel.serializeBinaryToWriter
      );
    }
    if (_instance.responses) {
      _writer.writeString(4, _instance.responses);
    }
  }

  private _financeEntityId: number;
  private _signature: string;
  private _userBankAccount?: common_message008.UserBankAccountModel;
  private _responses: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ContractSigningRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<ContractSigningRequest.AsObject>) {
    _value = _value || {};
    this.financeEntityId = _value.financeEntityId;
    this.signature = _value.signature;
    this.userBankAccount = _value.userBankAccount
      ? new common_message008.UserBankAccountModel(_value.userBankAccount)
      : undefined;
    this.responses = _value.responses;
    ContractSigningRequest.refineValues(this);
  }
  get financeEntityId(): number {
    return this._financeEntityId;
  }
  set financeEntityId(value: number) {
    this._financeEntityId = value;
  }
  get signature(): string {
    return this._signature;
  }
  set signature(value: string) {
    this._signature = value;
  }
  get userBankAccount(): common_message008.UserBankAccountModel | undefined {
    return this._userBankAccount;
  }
  set userBankAccount(
    value: common_message008.UserBankAccountModel | undefined
  ) {
    this._userBankAccount = value;
  }
  get responses(): string {
    return this._responses;
  }
  set responses(value: string) {
    this._responses = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ContractSigningRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ContractSigningRequest.AsObject {
    return {
      financeEntityId: this.financeEntityId,
      signature: this.signature,
      userBankAccount: this.userBankAccount
        ? this.userBankAccount.toObject()
        : undefined,
      responses: this.responses
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ContractSigningRequest.AsProtobufJSON {
    return {
      financeEntityId: this.financeEntityId,
      signature: this.signature,
      userBankAccount: this.userBankAccount
        ? this.userBankAccount.toProtobufJSON(options)
        : null,
      responses: this.responses
    };
  }
}
export module ContractSigningRequest {
  /**
   * Standard JavaScript object representation for ContractSigningRequest
   */
  export interface AsObject {
    financeEntityId: number;
    signature: string;
    userBankAccount?: common_message008.UserBankAccountModel.AsObject;
    responses: string;
  }

  /**
   * Protobuf JSON representation for ContractSigningRequest
   */
  export interface AsProtobufJSON {
    financeEntityId: number;
    signature: string;
    userBankAccount: common_message008.UserBankAccountModel.AsProtobufJSON | null;
    responses: string;
  }
}

/**
 * Message implementation for portal_user.ContractPreviewRequest
 */
export class ContractPreviewRequest implements GrpcMessage {
  static id = 'portal_user.ContractPreviewRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ContractPreviewRequest();
    ContractPreviewRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ContractPreviewRequest) {
    _instance.financeEntityId = _instance.financeEntityId || 0;
    _instance.userInformation = _instance.userInformation || undefined;
    _instance.userBankAccount = _instance.userBankAccount || undefined;
    _instance.responses = _instance.responses || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ContractPreviewRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.financeEntityId = _reader.readInt32();
          break;
        case 2:
          _instance.userInformation = new common_message008.UserInformationModel();
          _reader.readMessage(
            _instance.userInformation,
            common_message008.UserInformationModel.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.userBankAccount = new common_message008.UserBankAccountModel();
          _reader.readMessage(
            _instance.userBankAccount,
            common_message008.UserBankAccountModel.deserializeBinaryFromReader
          );
          break;
        case 4:
          _instance.responses = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    ContractPreviewRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ContractPreviewRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.financeEntityId) {
      _writer.writeInt32(1, _instance.financeEntityId);
    }
    if (_instance.userInformation) {
      _writer.writeMessage(
        2,
        _instance.userInformation as any,
        common_message008.UserInformationModel.serializeBinaryToWriter
      );
    }
    if (_instance.userBankAccount) {
      _writer.writeMessage(
        3,
        _instance.userBankAccount as any,
        common_message008.UserBankAccountModel.serializeBinaryToWriter
      );
    }
    if (_instance.responses) {
      _writer.writeString(4, _instance.responses);
    }
  }

  private _financeEntityId: number;
  private _userInformation?: common_message008.UserInformationModel;
  private _userBankAccount?: common_message008.UserBankAccountModel;
  private _responses: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ContractPreviewRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<ContractPreviewRequest.AsObject>) {
    _value = _value || {};
    this.financeEntityId = _value.financeEntityId;
    this.userInformation = _value.userInformation
      ? new common_message008.UserInformationModel(_value.userInformation)
      : undefined;
    this.userBankAccount = _value.userBankAccount
      ? new common_message008.UserBankAccountModel(_value.userBankAccount)
      : undefined;
    this.responses = _value.responses;
    ContractPreviewRequest.refineValues(this);
  }
  get financeEntityId(): number {
    return this._financeEntityId;
  }
  set financeEntityId(value: number) {
    this._financeEntityId = value;
  }
  get userInformation(): common_message008.UserInformationModel | undefined {
    return this._userInformation;
  }
  set userInformation(
    value: common_message008.UserInformationModel | undefined
  ) {
    this._userInformation = value;
  }
  get userBankAccount(): common_message008.UserBankAccountModel | undefined {
    return this._userBankAccount;
  }
  set userBankAccount(
    value: common_message008.UserBankAccountModel | undefined
  ) {
    this._userBankAccount = value;
  }
  get responses(): string {
    return this._responses;
  }
  set responses(value: string) {
    this._responses = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ContractPreviewRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ContractPreviewRequest.AsObject {
    return {
      financeEntityId: this.financeEntityId,
      userInformation: this.userInformation
        ? this.userInformation.toObject()
        : undefined,
      userBankAccount: this.userBankAccount
        ? this.userBankAccount.toObject()
        : undefined,
      responses: this.responses
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ContractPreviewRequest.AsProtobufJSON {
    return {
      financeEntityId: this.financeEntityId,
      userInformation: this.userInformation
        ? this.userInformation.toProtobufJSON(options)
        : null,
      userBankAccount: this.userBankAccount
        ? this.userBankAccount.toProtobufJSON(options)
        : null,
      responses: this.responses
    };
  }
}
export module ContractPreviewRequest {
  /**
   * Standard JavaScript object representation for ContractPreviewRequest
   */
  export interface AsObject {
    financeEntityId: number;
    userInformation?: common_message008.UserInformationModel.AsObject;
    userBankAccount?: common_message008.UserBankAccountModel.AsObject;
    responses: string;
  }

  /**
   * Protobuf JSON representation for ContractPreviewRequest
   */
  export interface AsProtobufJSON {
    financeEntityId: number;
    userInformation: common_message008.UserInformationModel.AsProtobufJSON | null;
    userBankAccount: common_message008.UserBankAccountModel.AsProtobufJSON | null;
    responses: string;
  }
}

/**
 * Message implementation for portal_user.ContractSigningResponse
 */
export class ContractSigningResponse implements GrpcMessage {
  static id = 'portal_user.ContractSigningResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ContractSigningResponse();
    ContractSigningResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ContractSigningResponse) {
    _instance.emailVerified = _instance.emailVerified || false;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ContractSigningResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.emailVerified = _reader.readBool();
          break;
        default:
          _reader.skipField();
      }
    }

    ContractSigningResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ContractSigningResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.emailVerified) {
      _writer.writeBool(1, _instance.emailVerified);
    }
  }

  private _emailVerified: boolean;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ContractSigningResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<ContractSigningResponse.AsObject>) {
    _value = _value || {};
    this.emailVerified = _value.emailVerified;
    ContractSigningResponse.refineValues(this);
  }
  get emailVerified(): boolean {
    return this._emailVerified;
  }
  set emailVerified(value: boolean) {
    this._emailVerified = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ContractSigningResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ContractSigningResponse.AsObject {
    return {
      emailVerified: this.emailVerified
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ContractSigningResponse.AsProtobufJSON {
    return {
      emailVerified: this.emailVerified
    };
  }
}
export module ContractSigningResponse {
  /**
   * Standard JavaScript object representation for ContractSigningResponse
   */
  export interface AsObject {
    emailVerified: boolean;
  }

  /**
   * Protobuf JSON representation for ContractSigningResponse
   */
  export interface AsProtobufJSON {
    emailVerified: boolean;
  }
}

/**
 * Message implementation for portal_user.ContractPreviewResponse
 */
export class ContractPreviewResponse implements GrpcMessage {
  static id = 'portal_user.ContractPreviewResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ContractPreviewResponse();
    ContractPreviewResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ContractPreviewResponse) {
    _instance.fileId = _instance.fileId || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ContractPreviewResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.fileId = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    ContractPreviewResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ContractPreviewResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.fileId) {
      _writer.writeInt32(1, _instance.fileId);
    }
  }

  private _fileId: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ContractPreviewResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<ContractPreviewResponse.AsObject>) {
    _value = _value || {};
    this.fileId = _value.fileId;
    ContractPreviewResponse.refineValues(this);
  }
  get fileId(): number {
    return this._fileId;
  }
  set fileId(value: number) {
    this._fileId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ContractPreviewResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ContractPreviewResponse.AsObject {
    return {
      fileId: this.fileId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ContractPreviewResponse.AsProtobufJSON {
    return {
      fileId: this.fileId
    };
  }
}
export module ContractPreviewResponse {
  /**
   * Standard JavaScript object representation for ContractPreviewResponse
   */
  export interface AsObject {
    fileId: number;
  }

  /**
   * Protobuf JSON representation for ContractPreviewResponse
   */
  export interface AsProtobufJSON {
    fileId: number;
  }
}

/**
 * Message implementation for portal_user.SignReferralContractResponse
 */
export class SignReferralContractResponse implements GrpcMessage {
  static id = 'portal_user.SignReferralContractResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new SignReferralContractResponse();
    SignReferralContractResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: SignReferralContractResponse) {
    _instance.fileId = _instance.fileId || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: SignReferralContractResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.fileId = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    SignReferralContractResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: SignReferralContractResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.fileId) {
      _writer.writeInt32(1, _instance.fileId);
    }
  }

  private _fileId: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of SignReferralContractResponse to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<SignReferralContractResponse.AsObject>
  ) {
    _value = _value || {};
    this.fileId = _value.fileId;
    SignReferralContractResponse.refineValues(this);
  }
  get fileId(): number {
    return this._fileId;
  }
  set fileId(value: number) {
    this._fileId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    SignReferralContractResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): SignReferralContractResponse.AsObject {
    return {
      fileId: this.fileId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): SignReferralContractResponse.AsProtobufJSON {
    return {
      fileId: this.fileId
    };
  }
}
export module SignReferralContractResponse {
  /**
   * Standard JavaScript object representation for SignReferralContractResponse
   */
  export interface AsObject {
    fileId: number;
  }

  /**
   * Protobuf JSON representation for SignReferralContractResponse
   */
  export interface AsProtobufJSON {
    fileId: number;
  }
}

/**
 * Message implementation for portal_user.ReferralContractSignedResponse
 */
export class ReferralContractSignedResponse implements GrpcMessage {
  static id = 'portal_user.ReferralContractSignedResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ReferralContractSignedResponse();
    ReferralContractSignedResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ReferralContractSignedResponse) {
    _instance.hasSigned = _instance.hasSigned || false;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ReferralContractSignedResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.hasSigned = _reader.readBool();
          break;
        default:
          _reader.skipField();
      }
    }

    ReferralContractSignedResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ReferralContractSignedResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.hasSigned) {
      _writer.writeBool(1, _instance.hasSigned);
    }
  }

  private _hasSigned: boolean;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ReferralContractSignedResponse to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<ReferralContractSignedResponse.AsObject>
  ) {
    _value = _value || {};
    this.hasSigned = _value.hasSigned;
    ReferralContractSignedResponse.refineValues(this);
  }
  get hasSigned(): boolean {
    return this._hasSigned;
  }
  set hasSigned(value: boolean) {
    this._hasSigned = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ReferralContractSignedResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ReferralContractSignedResponse.AsObject {
    return {
      hasSigned: this.hasSigned
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ReferralContractSignedResponse.AsProtobufJSON {
    return {
      hasSigned: this.hasSigned
    };
  }
}
export module ReferralContractSignedResponse {
  /**
   * Standard JavaScript object representation for ReferralContractSignedResponse
   */
  export interface AsObject {
    hasSigned: boolean;
  }

  /**
   * Protobuf JSON representation for ReferralContractSignedResponse
   */
  export interface AsProtobufJSON {
    hasSigned: boolean;
  }
}

/**
 * Message implementation for portal_user.SendContractAndEmailValidationResponse
 */
export class SendContractAndEmailValidationResponse implements GrpcMessage {
  static id = 'portal_user.SendContractAndEmailValidationResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new SendContractAndEmailValidationResponse();
    SendContractAndEmailValidationResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: SendContractAndEmailValidationResponse) {
    _instance.correct = _instance.correct || false;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: SendContractAndEmailValidationResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.correct = _reader.readBool();
          break;
        default:
          _reader.skipField();
      }
    }

    SendContractAndEmailValidationResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: SendContractAndEmailValidationResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.correct) {
      _writer.writeBool(1, _instance.correct);
    }
  }

  private _correct: boolean;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of SendContractAndEmailValidationResponse to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<SendContractAndEmailValidationResponse.AsObject>
  ) {
    _value = _value || {};
    this.correct = _value.correct;
    SendContractAndEmailValidationResponse.refineValues(this);
  }
  get correct(): boolean {
    return this._correct;
  }
  set correct(value: boolean) {
    this._correct = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    SendContractAndEmailValidationResponse.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): SendContractAndEmailValidationResponse.AsObject {
    return {
      correct: this.correct
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): SendContractAndEmailValidationResponse.AsProtobufJSON {
    return {
      correct: this.correct
    };
  }
}
export module SendContractAndEmailValidationResponse {
  /**
   * Standard JavaScript object representation for SendContractAndEmailValidationResponse
   */
  export interface AsObject {
    correct: boolean;
  }

  /**
   * Protobuf JSON representation for SendContractAndEmailValidationResponse
   */
  export interface AsProtobufJSON {
    correct: boolean;
  }
}

/**
 * Message implementation for portal_user.ConfirmContractAndEmailRequest
 */
export class ConfirmContractAndEmailRequest implements GrpcMessage {
  static id = 'portal_user.ConfirmContractAndEmailRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ConfirmContractAndEmailRequest();
    ConfirmContractAndEmailRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ConfirmContractAndEmailRequest) {
    _instance.identityId = _instance.identityId || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ConfirmContractAndEmailRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.identityId = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    ConfirmContractAndEmailRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ConfirmContractAndEmailRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.identityId) {
      _writer.writeString(1, _instance.identityId);
    }
  }

  private _identityId: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ConfirmContractAndEmailRequest to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<ConfirmContractAndEmailRequest.AsObject>
  ) {
    _value = _value || {};
    this.identityId = _value.identityId;
    ConfirmContractAndEmailRequest.refineValues(this);
  }
  get identityId(): string {
    return this._identityId;
  }
  set identityId(value: string) {
    this._identityId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ConfirmContractAndEmailRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ConfirmContractAndEmailRequest.AsObject {
    return {
      identityId: this.identityId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ConfirmContractAndEmailRequest.AsProtobufJSON {
    return {
      identityId: this.identityId
    };
  }
}
export module ConfirmContractAndEmailRequest {
  /**
   * Standard JavaScript object representation for ConfirmContractAndEmailRequest
   */
  export interface AsObject {
    identityId: string;
  }

  /**
   * Protobuf JSON representation for ConfirmContractAndEmailRequest
   */
  export interface AsProtobufJSON {
    identityId: string;
  }
}

/**
 * Message implementation for portal_user.ConfirmContractAndEmailResponse
 */
export class ConfirmContractAndEmailResponse implements GrpcMessage {
  static id = 'portal_user.ConfirmContractAndEmailResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ConfirmContractAndEmailResponse();
    ConfirmContractAndEmailResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ConfirmContractAndEmailResponse) {
    _instance.ok = _instance.ok || false;
    _instance.msgError = _instance.msgError || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ConfirmContractAndEmailResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.ok = _reader.readBool();
          break;
        case 2:
          _instance.msgError = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    ConfirmContractAndEmailResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ConfirmContractAndEmailResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.ok) {
      _writer.writeBool(1, _instance.ok);
    }
    if (_instance.msgError) {
      _writer.writeString(2, _instance.msgError);
    }
  }

  private _ok: boolean;
  private _msgError: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ConfirmContractAndEmailResponse to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<ConfirmContractAndEmailResponse.AsObject>
  ) {
    _value = _value || {};
    this.ok = _value.ok;
    this.msgError = _value.msgError;
    ConfirmContractAndEmailResponse.refineValues(this);
  }
  get ok(): boolean {
    return this._ok;
  }
  set ok(value: boolean) {
    this._ok = value;
  }
  get msgError(): string {
    return this._msgError;
  }
  set msgError(value: string) {
    this._msgError = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ConfirmContractAndEmailResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ConfirmContractAndEmailResponse.AsObject {
    return {
      ok: this.ok,
      msgError: this.msgError
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ConfirmContractAndEmailResponse.AsProtobufJSON {
    return {
      ok: this.ok,
      msgError: this.msgError
    };
  }
}
export module ConfirmContractAndEmailResponse {
  /**
   * Standard JavaScript object representation for ConfirmContractAndEmailResponse
   */
  export interface AsObject {
    ok: boolean;
    msgError: string;
  }

  /**
   * Protobuf JSON representation for ConfirmContractAndEmailResponse
   */
  export interface AsProtobufJSON {
    ok: boolean;
    msgError: string;
  }
}

/**
 * Message implementation for portal_user.MeFinancialEntityModel
 */
export class MeFinancialEntityModel implements GrpcMessage {
  static id = 'portal_user.MeFinancialEntityModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new MeFinancialEntityModel();
    MeFinancialEntityModel.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: MeFinancialEntityModel) {
    _instance.userFinancialEntity = _instance.userFinancialEntity || undefined;
    _instance.contract = _instance.contract || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: MeFinancialEntityModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.userFinancialEntity = new common_message008.UserFinancialEntityModel();
          _reader.readMessage(
            _instance.userFinancialEntity,
            common_message008.UserFinancialEntityModel
              .deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.contract = new MeFinancialEntityModel.Contract();
          _reader.readMessage(
            _instance.contract,
            MeFinancialEntityModel.Contract.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    MeFinancialEntityModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: MeFinancialEntityModel,
    _writer: BinaryWriter
  ) {
    if (_instance.userFinancialEntity) {
      _writer.writeMessage(
        1,
        _instance.userFinancialEntity as any,
        common_message008.UserFinancialEntityModel.serializeBinaryToWriter
      );
    }
    if (_instance.contract) {
      _writer.writeMessage(
        2,
        _instance.contract as any,
        MeFinancialEntityModel.Contract.serializeBinaryToWriter
      );
    }
  }

  private _userFinancialEntity?: common_message008.UserFinancialEntityModel;
  private _contract?: MeFinancialEntityModel.Contract;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of MeFinancialEntityModel to deeply clone from
   */
  constructor(_value?: RecursivePartial<MeFinancialEntityModel.AsObject>) {
    _value = _value || {};
    this.userFinancialEntity = _value.userFinancialEntity
      ? new common_message008.UserFinancialEntityModel(
          _value.userFinancialEntity
        )
      : undefined;
    this.contract = _value.contract
      ? new MeFinancialEntityModel.Contract(_value.contract)
      : undefined;
    MeFinancialEntityModel.refineValues(this);
  }
  get userFinancialEntity():
    | common_message008.UserFinancialEntityModel
    | undefined {
    return this._userFinancialEntity;
  }
  set userFinancialEntity(
    value: common_message008.UserFinancialEntityModel | undefined
  ) {
    this._userFinancialEntity = value;
  }
  get contract(): MeFinancialEntityModel.Contract | undefined {
    return this._contract;
  }
  set contract(value: MeFinancialEntityModel.Contract | undefined) {
    this._contract = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    MeFinancialEntityModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): MeFinancialEntityModel.AsObject {
    return {
      userFinancialEntity: this.userFinancialEntity
        ? this.userFinancialEntity.toObject()
        : undefined,
      contract: this.contract ? this.contract.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): MeFinancialEntityModel.AsProtobufJSON {
    return {
      userFinancialEntity: this.userFinancialEntity
        ? this.userFinancialEntity.toProtobufJSON(options)
        : null,
      contract: this.contract ? this.contract.toProtobufJSON(options) : null
    };
  }
}
export module MeFinancialEntityModel {
  /**
   * Standard JavaScript object representation for MeFinancialEntityModel
   */
  export interface AsObject {
    userFinancialEntity?: common_message008.UserFinancialEntityModel.AsObject;
    contract?: MeFinancialEntityModel.Contract.AsObject;
  }

  /**
   * Protobuf JSON representation for MeFinancialEntityModel
   */
  export interface AsProtobufJSON {
    userFinancialEntity: common_message008.UserFinancialEntityModel.AsProtobufJSON | null;
    contract: MeFinancialEntityModel.Contract.AsProtobufJSON | null;
  }

  /**
   * Message implementation for portal_user.MeFinancialEntityModel.Contract
   */
  export class Contract implements GrpcMessage {
    static id = 'portal_user.MeFinancialEntityModel.Contract';

    /**
     * Deserialize binary data to message
     * @param instance message instance
     */
    static deserializeBinary(bytes: ByteSource) {
      const instance = new Contract();
      Contract.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
      return instance;
    }

    /**
     * Check all the properties and set default protobuf values if necessary
     * @param _instance message instance
     */
    static refineValues(_instance: Contract) {
      _instance.signed = _instance.signed || false;
      _instance.state = _instance.state || '';
      _instance.count = _instance.count || 0;
      _instance.fileId = _instance.fileId || 0;
    }

    /**
     * Deserializes / reads binary message into message instance using provided binary reader
     * @param _instance message instance
     * @param _reader binary reader instance
     */
    static deserializeBinaryFromReader(
      _instance: Contract,
      _reader: BinaryReader
    ) {
      while (_reader.nextField()) {
        if (_reader.isEndGroup()) break;

        switch (_reader.getFieldNumber()) {
          case 1:
            _instance.signed = _reader.readBool();
            break;
          case 2:
            _instance.state = _reader.readString();
            break;
          case 3:
            _instance.count = _reader.readInt32();
            break;
          case 4:
            _instance.fileId = _reader.readInt32();
            break;
          default:
            _reader.skipField();
        }
      }

      Contract.refineValues(_instance);
    }

    /**
     * Serializes a message to binary format using provided binary reader
     * @param _instance message instance
     * @param _writer binary writer instance
     */
    static serializeBinaryToWriter(_instance: Contract, _writer: BinaryWriter) {
      if (_instance.signed) {
        _writer.writeBool(1, _instance.signed);
      }
      if (_instance.state) {
        _writer.writeString(2, _instance.state);
      }
      if (_instance.count) {
        _writer.writeInt32(3, _instance.count);
      }
      if (_instance.fileId) {
        _writer.writeInt32(4, _instance.fileId);
      }
    }

    private _signed: boolean;
    private _state: string;
    private _count: number;
    private _fileId: number;

    /**
     * Message constructor. Initializes the properties and applies default Protobuf values if necessary
     * @param _value initial values object or instance of Contract to deeply clone from
     */
    constructor(_value?: RecursivePartial<Contract.AsObject>) {
      _value = _value || {};
      this.signed = _value.signed;
      this.state = _value.state;
      this.count = _value.count;
      this.fileId = _value.fileId;
      Contract.refineValues(this);
    }
    get signed(): boolean {
      return this._signed;
    }
    set signed(value: boolean) {
      this._signed = value;
    }
    get state(): string {
      return this._state;
    }
    set state(value: string) {
      this._state = value;
    }
    get count(): number {
      return this._count;
    }
    set count(value: number) {
      this._count = value;
    }
    get fileId(): number {
      return this._fileId;
    }
    set fileId(value: number) {
      this._fileId = value;
    }

    /**
     * Serialize message to binary data
     * @param instance message instance
     */
    serializeBinary() {
      const writer = new BinaryWriter();
      Contract.serializeBinaryToWriter(this, writer);
      return writer.getResultBuffer();
    }

    /**
     * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
     */
    toObject(): Contract.AsObject {
      return {
        signed: this.signed,
        state: this.state,
        count: this.count,
        fileId: this.fileId
      };
    }

    /**
     * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
     */
    toJSON() {
      return this.toObject();
    }

    /**
     * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
     * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
     * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
     */
    toProtobufJSON(
      // @ts-ignore
      options?: ToProtobufJSONOptions
    ): Contract.AsProtobufJSON {
      return {
        signed: this.signed,
        state: this.state,
        count: this.count,
        fileId: this.fileId
      };
    }
  }
  export module Contract {
    /**
     * Standard JavaScript object representation for Contract
     */
    export interface AsObject {
      signed: boolean;
      state: string;
      count: number;
      fileId: number;
    }

    /**
     * Protobuf JSON representation for Contract
     */
    export interface AsProtobufJSON {
      signed: boolean;
      state: string;
      count: number;
      fileId: number;
    }
  }
}

/**
 * Message implementation for portal_user.AnswerFirstStepRequest
 */
export class AnswerFirstStepRequest implements GrpcMessage {
  static id = 'portal_user.AnswerFirstStepRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new AnswerFirstStepRequest();
    AnswerFirstStepRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: AnswerFirstStepRequest) {
    _instance.answers = _instance.answers || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: AnswerFirstStepRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new AnswerFirstStepModel();
          _reader.readMessage(
            messageInitializer1,
            AnswerFirstStepModel.deserializeBinaryFromReader
          );
          (_instance.answers = _instance.answers || []).push(
            messageInitializer1
          );
          break;
        default:
          _reader.skipField();
      }
    }

    AnswerFirstStepRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: AnswerFirstStepRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.answers && _instance.answers.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.answers as any,
        AnswerFirstStepModel.serializeBinaryToWriter
      );
    }
  }

  private _answers?: AnswerFirstStepModel[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of AnswerFirstStepRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<AnswerFirstStepRequest.AsObject>) {
    _value = _value || {};
    this.answers = (_value.answers || []).map(m => new AnswerFirstStepModel(m));
    AnswerFirstStepRequest.refineValues(this);
  }
  get answers(): AnswerFirstStepModel[] | undefined {
    return this._answers;
  }
  set answers(value: AnswerFirstStepModel[] | undefined) {
    this._answers = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    AnswerFirstStepRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): AnswerFirstStepRequest.AsObject {
    return {
      answers: (this.answers || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): AnswerFirstStepRequest.AsProtobufJSON {
    return {
      answers: (this.answers || []).map(m => m.toProtobufJSON(options))
    };
  }
}
export module AnswerFirstStepRequest {
  /**
   * Standard JavaScript object representation for AnswerFirstStepRequest
   */
  export interface AsObject {
    answers?: AnswerFirstStepModel.AsObject[];
  }

  /**
   * Protobuf JSON representation for AnswerFirstStepRequest
   */
  export interface AsProtobufJSON {
    answers: AnswerFirstStepModel.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for portal_user.AnswerFirstStepModel
 */
export class AnswerFirstStepModel implements GrpcMessage {
  static id = 'portal_user.AnswerFirstStepModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new AnswerFirstStepModel();
    AnswerFirstStepModel.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: AnswerFirstStepModel) {
    _instance.answerValue = _instance.answerValue || '';
    _instance.questionId = _instance.questionId || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: AnswerFirstStepModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.answerValue = _reader.readString();
          break;
        case 2:
          _instance.questionId = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    AnswerFirstStepModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: AnswerFirstStepModel,
    _writer: BinaryWriter
  ) {
    if (_instance.answerValue) {
      _writer.writeString(1, _instance.answerValue);
    }
    if (_instance.questionId) {
      _writer.writeInt32(2, _instance.questionId);
    }
  }

  private _answerValue: string;
  private _questionId: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of AnswerFirstStepModel to deeply clone from
   */
  constructor(_value?: RecursivePartial<AnswerFirstStepModel.AsObject>) {
    _value = _value || {};
    this.answerValue = _value.answerValue;
    this.questionId = _value.questionId;
    AnswerFirstStepModel.refineValues(this);
  }
  get answerValue(): string {
    return this._answerValue;
  }
  set answerValue(value: string) {
    this._answerValue = value;
  }
  get questionId(): number {
    return this._questionId;
  }
  set questionId(value: number) {
    this._questionId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    AnswerFirstStepModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): AnswerFirstStepModel.AsObject {
    return {
      answerValue: this.answerValue,
      questionId: this.questionId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): AnswerFirstStepModel.AsProtobufJSON {
    return {
      answerValue: this.answerValue,
      questionId: this.questionId
    };
  }
}
export module AnswerFirstStepModel {
  /**
   * Standard JavaScript object representation for AnswerFirstStepModel
   */
  export interface AsObject {
    answerValue: string;
    questionId: number;
  }

  /**
   * Protobuf JSON representation for AnswerFirstStepModel
   */
  export interface AsProtobufJSON {
    answerValue: string;
    questionId: number;
  }
}

/**
 * Message implementation for portal_user.GetMeDocumentsRequest
 */
export class GetMeDocumentsRequest implements GrpcMessage {
  static id = 'portal_user.GetMeDocumentsRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetMeDocumentsRequest();
    GetMeDocumentsRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetMeDocumentsRequest) {
    _instance.type = _instance.type || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetMeDocumentsRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.type = _reader.readEnum();
          break;
        default:
          _reader.skipField();
      }
    }

    GetMeDocumentsRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetMeDocumentsRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.type) {
      _writer.writeEnum(1, _instance.type);
    }
  }

  private _type: DocumentType;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetMeDocumentsRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetMeDocumentsRequest.AsObject>) {
    _value = _value || {};
    this.type = _value.type;
    GetMeDocumentsRequest.refineValues(this);
  }
  get type(): DocumentType {
    return this._type;
  }
  set type(value: DocumentType) {
    this._type = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetMeDocumentsRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetMeDocumentsRequest.AsObject {
    return {
      type: this.type
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetMeDocumentsRequest.AsProtobufJSON {
    return {
      type:
        DocumentType[
          this.type === null || this.type === undefined ? 0 : this.type
        ]
    };
  }
}
export module GetMeDocumentsRequest {
  /**
   * Standard JavaScript object representation for GetMeDocumentsRequest
   */
  export interface AsObject {
    type: DocumentType;
  }

  /**
   * Protobuf JSON representation for GetMeDocumentsRequest
   */
  export interface AsProtobufJSON {
    type: string;
  }
}

/**
 * Message implementation for portal_user.GetMeDocumentsResponse
 */
export class GetMeDocumentsResponse implements GrpcMessage {
  static id = 'portal_user.GetMeDocumentsResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetMeDocumentsResponse();
    GetMeDocumentsResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetMeDocumentsResponse) {
    _instance.document = _instance.document || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetMeDocumentsResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new common_message008.FileStorageModel();
          _reader.readMessage(
            messageInitializer1,
            common_message008.FileStorageModel.deserializeBinaryFromReader
          );
          (_instance.document = _instance.document || []).push(
            messageInitializer1
          );
          break;
        default:
          _reader.skipField();
      }
    }

    GetMeDocumentsResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetMeDocumentsResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.document && _instance.document.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.document as any,
        common_message008.FileStorageModel.serializeBinaryToWriter
      );
    }
  }

  private _document?: common_message008.FileStorageModel[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetMeDocumentsResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetMeDocumentsResponse.AsObject>) {
    _value = _value || {};
    this.document = (_value.document || []).map(
      m => new common_message008.FileStorageModel(m)
    );
    GetMeDocumentsResponse.refineValues(this);
  }
  get document(): common_message008.FileStorageModel[] | undefined {
    return this._document;
  }
  set document(value: common_message008.FileStorageModel[] | undefined) {
    this._document = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetMeDocumentsResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetMeDocumentsResponse.AsObject {
    return {
      document: (this.document || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetMeDocumentsResponse.AsProtobufJSON {
    return {
      document: (this.document || []).map(m => m.toProtobufJSON(options))
    };
  }
}
export module GetMeDocumentsResponse {
  /**
   * Standard JavaScript object representation for GetMeDocumentsResponse
   */
  export interface AsObject {
    document?: common_message008.FileStorageModel.AsObject[];
  }

  /**
   * Protobuf JSON representation for GetMeDocumentsResponse
   */
  export interface AsProtobufJSON {
    document: common_message008.FileStorageModel.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for portal_user.ShowAdvisorPopupRequest
 */
export class ShowAdvisorPopupRequest implements GrpcMessage {
  static id = 'portal_user.ShowAdvisorPopupRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ShowAdvisorPopupRequest();
    ShowAdvisorPopupRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ShowAdvisorPopupRequest) {
    _instance.show = _instance.show || false;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ShowAdvisorPopupRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.show = _reader.readBool();
          break;
        default:
          _reader.skipField();
      }
    }

    ShowAdvisorPopupRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ShowAdvisorPopupRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.show) {
      _writer.writeBool(1, _instance.show);
    }
  }

  private _show: boolean;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ShowAdvisorPopupRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<ShowAdvisorPopupRequest.AsObject>) {
    _value = _value || {};
    this.show = _value.show;
    ShowAdvisorPopupRequest.refineValues(this);
  }
  get show(): boolean {
    return this._show;
  }
  set show(value: boolean) {
    this._show = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ShowAdvisorPopupRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ShowAdvisorPopupRequest.AsObject {
    return {
      show: this.show
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ShowAdvisorPopupRequest.AsProtobufJSON {
    return {
      show: this.show
    };
  }
}
export module ShowAdvisorPopupRequest {
  /**
   * Standard JavaScript object representation for ShowAdvisorPopupRequest
   */
  export interface AsObject {
    show: boolean;
  }

  /**
   * Protobuf JSON representation for ShowAdvisorPopupRequest
   */
  export interface AsProtobufJSON {
    show: boolean;
  }
}

/**
 * Message implementation for portal_user.GetMeAnswerFinancialInformationResponse
 */
export class GetMeAnswerFinancialInformationResponse implements GrpcMessage {
  static id = 'portal_user.GetMeAnswerFinancialInformationResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetMeAnswerFinancialInformationResponse();
    GetMeAnswerFinancialInformationResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetMeAnswerFinancialInformationResponse) {
    _instance.patrimony = _instance.patrimony || '';
    _instance.income = _instance.income || '';
    _instance.patrimonyValue = _instance.patrimonyValue || '';
    _instance.incomeValue = _instance.incomeValue || '';
    _instance.patrimonyCode = _instance.patrimonyCode || '';
    _instance.incomeCode = _instance.incomeCode || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetMeAnswerFinancialInformationResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.patrimony = _reader.readString();
          break;
        case 2:
          _instance.income = _reader.readString();
          break;
        case 3:
          _instance.patrimonyValue = _reader.readString();
          break;
        case 4:
          _instance.incomeValue = _reader.readString();
          break;
        case 5:
          _instance.patrimonyCode = _reader.readString();
          break;
        case 6:
          _instance.incomeCode = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    GetMeAnswerFinancialInformationResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetMeAnswerFinancialInformationResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.patrimony) {
      _writer.writeString(1, _instance.patrimony);
    }
    if (_instance.income) {
      _writer.writeString(2, _instance.income);
    }
    if (_instance.patrimonyValue) {
      _writer.writeString(3, _instance.patrimonyValue);
    }
    if (_instance.incomeValue) {
      _writer.writeString(4, _instance.incomeValue);
    }
    if (_instance.patrimonyCode) {
      _writer.writeString(5, _instance.patrimonyCode);
    }
    if (_instance.incomeCode) {
      _writer.writeString(6, _instance.incomeCode);
    }
  }

  private _patrimony: string;
  private _income: string;
  private _patrimonyValue: string;
  private _incomeValue: string;
  private _patrimonyCode: string;
  private _incomeCode: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetMeAnswerFinancialInformationResponse to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<GetMeAnswerFinancialInformationResponse.AsObject>
  ) {
    _value = _value || {};
    this.patrimony = _value.patrimony;
    this.income = _value.income;
    this.patrimonyValue = _value.patrimonyValue;
    this.incomeValue = _value.incomeValue;
    this.patrimonyCode = _value.patrimonyCode;
    this.incomeCode = _value.incomeCode;
    GetMeAnswerFinancialInformationResponse.refineValues(this);
  }
  get patrimony(): string {
    return this._patrimony;
  }
  set patrimony(value: string) {
    this._patrimony = value;
  }
  get income(): string {
    return this._income;
  }
  set income(value: string) {
    this._income = value;
  }
  get patrimonyValue(): string {
    return this._patrimonyValue;
  }
  set patrimonyValue(value: string) {
    this._patrimonyValue = value;
  }
  get incomeValue(): string {
    return this._incomeValue;
  }
  set incomeValue(value: string) {
    this._incomeValue = value;
  }
  get patrimonyCode(): string {
    return this._patrimonyCode;
  }
  set patrimonyCode(value: string) {
    this._patrimonyCode = value;
  }
  get incomeCode(): string {
    return this._incomeCode;
  }
  set incomeCode(value: string) {
    this._incomeCode = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetMeAnswerFinancialInformationResponse.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetMeAnswerFinancialInformationResponse.AsObject {
    return {
      patrimony: this.patrimony,
      income: this.income,
      patrimonyValue: this.patrimonyValue,
      incomeValue: this.incomeValue,
      patrimonyCode: this.patrimonyCode,
      incomeCode: this.incomeCode
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetMeAnswerFinancialInformationResponse.AsProtobufJSON {
    return {
      patrimony: this.patrimony,
      income: this.income,
      patrimonyValue: this.patrimonyValue,
      incomeValue: this.incomeValue,
      patrimonyCode: this.patrimonyCode,
      incomeCode: this.incomeCode
    };
  }
}
export module GetMeAnswerFinancialInformationResponse {
  /**
   * Standard JavaScript object representation for GetMeAnswerFinancialInformationResponse
   */
  export interface AsObject {
    patrimony: string;
    income: string;
    patrimonyValue: string;
    incomeValue: string;
    patrimonyCode: string;
    incomeCode: string;
  }

  /**
   * Protobuf JSON representation for GetMeAnswerFinancialInformationResponse
   */
  export interface AsProtobufJSON {
    patrimony: string;
    income: string;
    patrimonyValue: string;
    incomeValue: string;
    patrimonyCode: string;
    incomeCode: string;
  }
}

/**
 * Message implementation for portal_user.UpdateUserProfileRequest
 */
export class UpdateUserProfileRequest implements GrpcMessage {
  static id = 'portal_user.UpdateUserProfileRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UpdateUserProfileRequest();
    UpdateUserProfileRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UpdateUserProfileRequest) {
    _instance.preferredName = _instance.preferredName || '';
    _instance.profession = _instance.profession || '';
    _instance.employmentSituation = _instance.employmentSituation || '';
    _instance.phone = _instance.phone || '';
    _instance.communeId = _instance.communeId || 0;
    _instance.address = _instance.address || '';
    _instance.averageIncomeRange = _instance.averageIncomeRange || '';
    _instance.equityFinancialRange = _instance.equityFinancialRange || '';
    _instance.educationLevel = _instance.educationLevel || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UpdateUserProfileRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.preferredName = _reader.readString();
          break;
        case 2:
          _instance.profession = _reader.readString();
          break;
        case 3:
          _instance.employmentSituation = _reader.readString();
          break;
        case 4:
          _instance.phone = _reader.readString();
          break;
        case 5:
          _instance.communeId = _reader.readInt32();
          break;
        case 6:
          _instance.address = _reader.readString();
          break;
        case 7:
          _instance.averageIncomeRange = _reader.readString();
          break;
        case 8:
          _instance.equityFinancialRange = _reader.readString();
          break;
        case 9:
          _instance.educationLevel = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    UpdateUserProfileRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: UpdateUserProfileRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.preferredName) {
      _writer.writeString(1, _instance.preferredName);
    }
    if (_instance.profession) {
      _writer.writeString(2, _instance.profession);
    }
    if (_instance.employmentSituation) {
      _writer.writeString(3, _instance.employmentSituation);
    }
    if (_instance.phone) {
      _writer.writeString(4, _instance.phone);
    }
    if (_instance.communeId) {
      _writer.writeInt32(5, _instance.communeId);
    }
    if (_instance.address) {
      _writer.writeString(6, _instance.address);
    }
    if (_instance.averageIncomeRange) {
      _writer.writeString(7, _instance.averageIncomeRange);
    }
    if (_instance.equityFinancialRange) {
      _writer.writeString(8, _instance.equityFinancialRange);
    }
    if (_instance.educationLevel) {
      _writer.writeString(9, _instance.educationLevel);
    }
  }

  private _preferredName: string;
  private _profession: string;
  private _employmentSituation: string;
  private _phone: string;
  private _communeId: number;
  private _address: string;
  private _averageIncomeRange: string;
  private _equityFinancialRange: string;
  private _educationLevel: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UpdateUserProfileRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<UpdateUserProfileRequest.AsObject>) {
    _value = _value || {};
    this.preferredName = _value.preferredName;
    this.profession = _value.profession;
    this.employmentSituation = _value.employmentSituation;
    this.phone = _value.phone;
    this.communeId = _value.communeId;
    this.address = _value.address;
    this.averageIncomeRange = _value.averageIncomeRange;
    this.equityFinancialRange = _value.equityFinancialRange;
    this.educationLevel = _value.educationLevel;
    UpdateUserProfileRequest.refineValues(this);
  }
  get preferredName(): string {
    return this._preferredName;
  }
  set preferredName(value: string) {
    this._preferredName = value;
  }
  get profession(): string {
    return this._profession;
  }
  set profession(value: string) {
    this._profession = value;
  }
  get employmentSituation(): string {
    return this._employmentSituation;
  }
  set employmentSituation(value: string) {
    this._employmentSituation = value;
  }
  get phone(): string {
    return this._phone;
  }
  set phone(value: string) {
    this._phone = value;
  }
  get communeId(): number {
    return this._communeId;
  }
  set communeId(value: number) {
    this._communeId = value;
  }
  get address(): string {
    return this._address;
  }
  set address(value: string) {
    this._address = value;
  }
  get averageIncomeRange(): string {
    return this._averageIncomeRange;
  }
  set averageIncomeRange(value: string) {
    this._averageIncomeRange = value;
  }
  get equityFinancialRange(): string {
    return this._equityFinancialRange;
  }
  set equityFinancialRange(value: string) {
    this._equityFinancialRange = value;
  }
  get educationLevel(): string {
    return this._educationLevel;
  }
  set educationLevel(value: string) {
    this._educationLevel = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UpdateUserProfileRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UpdateUserProfileRequest.AsObject {
    return {
      preferredName: this.preferredName,
      profession: this.profession,
      employmentSituation: this.employmentSituation,
      phone: this.phone,
      communeId: this.communeId,
      address: this.address,
      averageIncomeRange: this.averageIncomeRange,
      equityFinancialRange: this.equityFinancialRange,
      educationLevel: this.educationLevel
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UpdateUserProfileRequest.AsProtobufJSON {
    return {
      preferredName: this.preferredName,
      profession: this.profession,
      employmentSituation: this.employmentSituation,
      phone: this.phone,
      communeId: this.communeId,
      address: this.address,
      averageIncomeRange: this.averageIncomeRange,
      equityFinancialRange: this.equityFinancialRange,
      educationLevel: this.educationLevel
    };
  }
}
export module UpdateUserProfileRequest {
  /**
   * Standard JavaScript object representation for UpdateUserProfileRequest
   */
  export interface AsObject {
    preferredName: string;
    profession: string;
    employmentSituation: string;
    phone: string;
    communeId: number;
    address: string;
    averageIncomeRange: string;
    equityFinancialRange: string;
    educationLevel: string;
  }

  /**
   * Protobuf JSON representation for UpdateUserProfileRequest
   */
  export interface AsProtobufJSON {
    preferredName: string;
    profession: string;
    employmentSituation: string;
    phone: string;
    communeId: number;
    address: string;
    averageIncomeRange: string;
    equityFinancialRange: string;
    educationLevel: string;
  }
}

/**
 * Message implementation for portal_user.AddBankAccountRequest
 */
export class AddBankAccountRequest implements GrpcMessage {
  static id = 'portal_user.AddBankAccountRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new AddBankAccountRequest();
    AddBankAccountRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: AddBankAccountRequest) {
    _instance.accountNumber = _instance.accountNumber || '';
    _instance.type = _instance.type || '';
    _instance.bank = _instance.bank || '';
    _instance.currencyId = _instance.currencyId || 0;
    _instance.financialEntityId = _instance.financialEntityId || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: AddBankAccountRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.accountNumber = _reader.readString();
          break;
        case 2:
          _instance.type = _reader.readString();
          break;
        case 3:
          _instance.bank = _reader.readString();
          break;
        case 4:
          _instance.currencyId = _reader.readInt32();
          break;
        case 5:
          _instance.financialEntityId = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    AddBankAccountRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: AddBankAccountRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.accountNumber) {
      _writer.writeString(1, _instance.accountNumber);
    }
    if (_instance.type) {
      _writer.writeString(2, _instance.type);
    }
    if (_instance.bank) {
      _writer.writeString(3, _instance.bank);
    }
    if (_instance.currencyId) {
      _writer.writeInt32(4, _instance.currencyId);
    }
    if (_instance.financialEntityId) {
      _writer.writeInt32(5, _instance.financialEntityId);
    }
  }

  private _accountNumber: string;
  private _type: string;
  private _bank: string;
  private _currencyId: number;
  private _financialEntityId: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of AddBankAccountRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<AddBankAccountRequest.AsObject>) {
    _value = _value || {};
    this.accountNumber = _value.accountNumber;
    this.type = _value.type;
    this.bank = _value.bank;
    this.currencyId = _value.currencyId;
    this.financialEntityId = _value.financialEntityId;
    AddBankAccountRequest.refineValues(this);
  }
  get accountNumber(): string {
    return this._accountNumber;
  }
  set accountNumber(value: string) {
    this._accountNumber = value;
  }
  get type(): string {
    return this._type;
  }
  set type(value: string) {
    this._type = value;
  }
  get bank(): string {
    return this._bank;
  }
  set bank(value: string) {
    this._bank = value;
  }
  get currencyId(): number {
    return this._currencyId;
  }
  set currencyId(value: number) {
    this._currencyId = value;
  }
  get financialEntityId(): number {
    return this._financialEntityId;
  }
  set financialEntityId(value: number) {
    this._financialEntityId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    AddBankAccountRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): AddBankAccountRequest.AsObject {
    return {
      accountNumber: this.accountNumber,
      type: this.type,
      bank: this.bank,
      currencyId: this.currencyId,
      financialEntityId: this.financialEntityId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): AddBankAccountRequest.AsProtobufJSON {
    return {
      accountNumber: this.accountNumber,
      type: this.type,
      bank: this.bank,
      currencyId: this.currencyId,
      financialEntityId: this.financialEntityId
    };
  }
}
export module AddBankAccountRequest {
  /**
   * Standard JavaScript object representation for AddBankAccountRequest
   */
  export interface AsObject {
    accountNumber: string;
    type: string;
    bank: string;
    currencyId: number;
    financialEntityId: number;
  }

  /**
   * Protobuf JSON representation for AddBankAccountRequest
   */
  export interface AsProtobufJSON {
    accountNumber: string;
    type: string;
    bank: string;
    currencyId: number;
    financialEntityId: number;
  }
}

/**
 * Message implementation for portal_user.GetMeFirstActivityResponse
 */
export class GetMeFirstActivityResponse implements GrpcMessage {
  static id = 'portal_user.GetMeFirstActivityResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetMeFirstActivityResponse();
    GetMeFirstActivityResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetMeFirstActivityResponse) {
    _instance.value = _instance.value || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetMeFirstActivityResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.value = new GetMeActivity();
          _reader.readMessage(
            _instance.value,
            GetMeActivity.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    GetMeFirstActivityResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetMeFirstActivityResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.value) {
      _writer.writeMessage(
        1,
        _instance.value as any,
        GetMeActivity.serializeBinaryToWriter
      );
    }
  }

  private _value?: GetMeActivity;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetMeFirstActivityResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetMeFirstActivityResponse.AsObject>) {
    _value = _value || {};
    this.value = _value.value ? new GetMeActivity(_value.value) : undefined;
    GetMeFirstActivityResponse.refineValues(this);
  }
  get value(): GetMeActivity | undefined {
    return this._value;
  }
  set value(value: GetMeActivity | undefined) {
    this._value = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetMeFirstActivityResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetMeFirstActivityResponse.AsObject {
    return {
      value: this.value ? this.value.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetMeFirstActivityResponse.AsProtobufJSON {
    return {
      value: this.value ? this.value.toProtobufJSON(options) : null
    };
  }
}
export module GetMeFirstActivityResponse {
  /**
   * Standard JavaScript object representation for GetMeFirstActivityResponse
   */
  export interface AsObject {
    value?: GetMeActivity.AsObject;
  }

  /**
   * Protobuf JSON representation for GetMeFirstActivityResponse
   */
  export interface AsProtobufJSON {
    value: GetMeActivity.AsProtobufJSON | null;
  }
}
